/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { createRef } from 'react';
import { func, string, bool } from 'prop-types';
import { t } from '@jotforminc/translation';
import { serialize, classNames, handleCustomNavigation } from '@jotforminc/utils';
import { openFormLimitDialog } from '@jotforminc/limit-dialog';

import { isEnterprise } from '@jotforminc/enterprise-utils';
import Header from '../Header';
import WizardBody from '../Wizard/WizardBody';
import WizardItem from '../Wizard/WizardItem';
import TemplateModal from '../Wizard/WizardTemplateModal';

import texts from '../../core/texts';
import { templateConstants, isNewStandaloneActive } from '../StandaloneTemplates/templateConstants';
import { fetch } from '../../core/utils';
import StandaloneTemplates from '../StandaloneTemplates';
import { getCategory, getEnterpriseTemplate, getTemplate } from '../StandaloneTemplates/apiTemplates';
import { getUrlSearchParamByKey, getUsername } from '../StandaloneTemplates/utils';
import NewWizardTemplateModal from '../Wizard/NewWizardTemplateModal';
import LegacyTemplatesRenderer from './LegacyTemplatesRenderer';

export default class UseTemplate extends React.Component {
  constructor(props) {
    super(props);

    const searchPrefillData = getUrlSearchParamByKey({ key: 'search' });
    this.state = {
      modalProps: {},
      modalVisible: false,
      modalDetailsData: {},
      standaloneTemplates: [],
      modalContentLoading: true,
      categories: [],
      selectedCategory: undefined,
      searchKeyword: searchPrefillData || ''
    };

    this.useTemplateFrame = createRef();

    this.hideyMyformsModal = this.hideyMyformsModal.bind(this);
    this.handleTemplateLoad = this.handleTemplateLoad.bind(this);
    this.displayMyformsModal = this.displayMyformsModal.bind(this);
    this.getClonePayloadData = this.getClonePayloadData.bind(this);
    this.handleTemplateClone = this.handleTemplateClone.bind(this);
    this.frameAutoResizeHandler = this.frameAutoResizeHandler.bind(this);
    this.getTemplateType = this.getTemplateType.bind(this);
    this.useTemplateFrameLoader = this.useTemplateFrameLoader.bind(this);
    this.handleCloneGTMAction = this.handleCloneGTMAction.bind(this);
  }

  componentDidMount() {
    global.useTemplateAutoResizeHandler = this.frameAutoResizeHandler;
    global.displayMyformsModal = this.displayMyformsModal;
    global.useTemplateFrameLoader = this.useTemplateFrameLoader;
    const { creationLogger } = this.props;
    global.creationLogger = creationLogger;
  }

  componentWillUnmount() {
    delete global.useTemplateAutoResizeHandler;
    delete global.displayMyformsModal;
    delete global.creationLogger;
  }

  handleTemplateLoad() {
    if (this.useTemplateFrame.current) {
      const iframeContentWindow = this.useTemplateFrame.current.contentWindow;
      if (iframeContentWindow.FormTemplate) {
        iframeContentWindow.FormTemplate.cloneTemplate = this.handleTemplateClone;
      }
    }
  }

  handleCloneGTMAction(templateData, cloneActionKey = '') {
    if (!templateData || Object.keys(templateData).length === 0) return;
    if (!cloneActionKey || cloneActionKey === '') return;
    const { id, slug } = templateData;
    const slugOrIDActionKey = slug ? 'slug' : 'id';

    const payload = {
      event: cloneActionKey,
      [slugOrIDActionKey]: slug || id
    };
    const { dataLayer = [] } = window;
    dataLayer.push(payload);
  }

  async handleTemplateClone(templateData, element) {
    let previousElementContent;
    if (element) {
      previousElementContent = element.innerHTML;
      (element.querySelector('span') || element).innerHTML = 'Please wait...'.locale();
    }

    const {
      source, onFormCreate, actionLogger, teamID, user, portalRoot, folderID, viewProps = {}, creationLogger, isSignTemplates, username, handleBeforeCreatingAsset
    } = this.props;

    if (handleBeforeCreatingAsset) {
      await handleBeforeCreatingAsset();
    }

    const { onFormCreate: onFormCreateFromViewProps = false } = viewProps;
    const { templateType } = this.getTemplateType();
    const {
      successAction, failMessage, failAction, payload
    } = this.getClonePayloadData(templateType, templateData);

    const usernameData = getUsername(username, user);
    const { aliasType, gtmCloneActionKey } = templateConstants[templateType];
    const { slug, categories = '', ...rest } = payload;
    const salesforceTemplateCategoryID = '3254377341';
    const isSalesforceTemplate = categories.includes(salesforceTemplateCategoryID);
    if (creationLogger) {
      creationLogger(isSignTemplates ? 'useSignTemplate' : 'useTemplate');
    }

    const isAgentDirectory = templateType === 'agent-templates';
    return fetch(`/${isAgentDirectory ? 'agent-directory' : (aliasType || templateType)}/api`, {
      method: 'POST',
      body: serialize({
        ...rest, source, teamID, folderID
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...teamID ? { 'jf-team-id': teamID } : {}
      }
    }).then(r => r.json()).then(response => {
      const onFormCreateHandler = onFormCreateFromViewProps || onFormCreate;
      const {
        cloneRedirectionFunc = id => onFormCreateHandler(id, isSalesforceTemplate),
        extractIdFromResponse = res => {
          const { data: { id: newClonedResourceID } } = res;
          return newClonedResourceID;
        }
      } = templateConstants[templateType];

      const clonedTemplateID = extractIdFromResponse(response);
      if (clonedTemplateID) {
        if (actionLogger) {
          actionLogger({
            action: successAction,
            target: clonedTemplateID
          });
          if (templateType === 'app-templates') {
            // Also track for portal-app
            // Pls don't change the action obj.
            const portalAppsAction = { action: 'appCreated', target: JSON.stringify({ portalID: clonedTemplateID }) };
            actionLogger(portalAppsAction, 'portal-app');
          }
        }
        if (templateType === 'app-templates') {
          window.sessionStorage.setItem('app-creation-source', 'app-templates-wizard');
        }
        this.handleCloneGTMAction(payload, gtmCloneActionKey);
        cloneRedirectionFunc(clonedTemplateID, usernameData);
      } else {
        console.warn(failMessage); // eslint-disable-line
        console.error(response); // eslint-disable-line

        if (actionLogger) {
          actionLogger({
            action: failAction,
            target: '.jfWizard-button.forUseTemplate'
          });
        }
        if (response?.data['form-limit-exceed']) {
          const portalElement = portalRoot ? portalRoot : document.querySelector('#listing-portal-root');
          const limitedUser = user ? user : global?.user;
          if (portalElement !== null && limitedUser) {
            // eslint-disable-next-line no-unused-vars
            openFormLimitDialog({ user: limitedUser, portalRoot: portalElement }).catch(err => {
              // modal closed
            });
          }
        }
        element.innerHTML = previousElementContent;
      }
    }).catch(() => {
      if (element) {
        element.innerHTML = previousElementContent;
      }
    });
  }

  getClonePayloadData(templateType, template) {
    const { modalProps = {} } = this.state;
    const { source = '' } = this.props;

    const { getCloneTemplatePayload = () => {} } = templateConstants[templateType];
    return getCloneTemplatePayload(modalProps, template, source);
  }

  getTemplateType() {
    const {
      langPath, templatePath, categories, teamID, folderID
    } = this.props;
    const params = { categories, teamID, folderID };
    const queryParams = Object.keys(params).reduce((prev, currKey) => ({ ...prev, ...params[currKey] ? { [currKey]: params[currKey] } : {} }), {});
    const frameSrc = [
      langPath,
      templatePath,
      (categories || teamID || folderID) ? `?${new URLSearchParams(queryParams)}` : ''
    ].filter(Boolean).join('');

    const templateType = frameSrc.split('/')[1];
    return { templateType, frameSrc };
  }

  frameAutoResizeHandler(frameHeight) {
    if (this.useTemplateFrame && typeof frameHeight === 'number') {
      this.useTemplateFrame.current.style.height = `${frameHeight}px`;
    }
  }

  useTemplateFrameLoader() {
    this.setState({
      modalContentLoading: false
    });
  }

  displayMyformsModal({ ...modalProps }) {
    const { templateType } = this.getTemplateType();
    const { source } = this.props;

    this.setState({ modalProps, modalVisible: true });
    const { hasDetailedPreviewModal = false } = templateConstants[templateType];
    if (hasDetailedPreviewModal) {
      const {
        template, language, filters = {}, theme
      } = modalProps;
      const emptyCategoryData = { name: 'empty', id: '0' };
      const templateID = template.id || template._id || modalProps.id || modalProps._id;
      const useStandaloneApi = templateType === 'form-templates' && isEnterprise();
      if (useStandaloneApi) {
        getEnterpriseTemplate(templateID, theme).then(res => {
          const {
            data: {
              formTemplate: {
                _featuredCategory, similarTemplates = [], tabs = [], _clonecount, clonecount
              }
            }
          } = res?.data?.data || {};
          this.setState({
            modalDetailsData: {
              categoryData: _featuredCategory || emptyCategoryData,
              similarTemplates: similarTemplates,
              templateTabs: tabs,
              clonecount: _clonecount || clonecount
            }
          });
        }).catch(() => {
          this.setState({
            modalDetailsData: {
              DOM: '',
              similarTemplates: [],
              templateTabs: [],
              categoryData: emptyCategoryData
            }
          });
        });
      } else {
        getTemplate(templateType, templateID, source, theme, isEnterprise()).then(response => {
          const {
            data: {
              content: {
                DOM, featuredCategory, _featuredCategory, similarTemplates = [], tabs = [], _clonecount, clonecount
              }
            }
          } = response;
          getCategory(filters, { _id: (featuredCategory || _featuredCategory || '').trim() }, language, templateType, source).then(res => {
            this.setState({
              modalDetailsData: {
                DOM,
                categoryData: res?.data?.content || emptyCategoryData,
                similarTemplates: similarTemplates,
                templateTabs: tabs,
                clonecount: _clonecount || clonecount
              }
            });
          });
        }).catch(() => {
          this.setState({
            modalDetailsData: {
              DOM: '',
              similarTemplates: [],
              templateTabs: [],
              categoryData: emptyCategoryData
            }
          });
        });
      }
    }
  }

  hideyMyformsModal() {
    this.setState({ modalVisible: false, searchKeyword: '' });
    this.setState({
      modalDetailsData: {
        DOM: '',
        similarTemplates: [],
        templateTabs: [],
        categoryData: {}
      }
    });
  }

  render() {
    const {
      setView, actionLogger, backButtonVisible, canClose, source, isMobileApp, customHandleClone = null, user = {}, liteMode = false,
      onBack, onFormCreate, templateHeaderText, templateHeaderSubText, closable, hiddenHeader, viewProps = {}, username = '', liteModeActionButtonHandlers,
      customTrackingProjectName, teamID, useMagnetPortal
    } = this.props;

    const { defaultSelectedCategorySlug = null, onBack: onBackFromViewProps } = viewProps;
    const usernameData = getUsername(username, user);

    const {
      modalProps, modalVisible, modalContentLoading, modalDetailsData, standaloneTemplates, categories, selectedCategory, searchKeyword
    } = this.state;

    const { frameSrc, templateType } = this.getTemplateType();
    const newStandaloneActive = isNewStandaloneActive(frameSrc, isMobileApp, source);
    const {
      count = '',
      countKey = '',
      getCustomHeaderSubText = f => f,
      newPreviewModalActive = false
    } = templateConstants[templateType];

    const {
      extraSubTitleClasses = [],
      subTitle = t(templateHeaderSubText).replaceAll(countKey, count)
    } = !liteMode ? getCustomHeaderSubText({
      templateHeaderSubText, setView, countKey, count, teamID, username: usernameData
    }) : f => f;

    const headerProps = { extraSubTitleClasses };
    const newTemplateModal = newPreviewModalActive;

    return (
      <WizardItem className={classNames('isUseTemplate', !modalContentLoading && 'isFrameContentReady')} noGutter noMaxWidth>
        {!hiddenHeader && (
          <Header
            setView={setView}
            actionLogger={actionLogger}
            title={t(templateHeaderText)}
            subTitle={subTitle}
            backButtonVisible={backButtonVisible}
            onBackButtonClick={onBack || onBackFromViewProps}
            isModalVisible={modalVisible}
            onModalClose={this.hideyMyformsModal}
            noDecodeURI
            {...headerProps}
          />
        )}
        <WizardBody>
          {
            newStandaloneActive
              ? (
                <StandaloneTemplates
                  user={user}
                  source={source}
                  liteMode={liteMode}
                  frameSrc={frameSrc}
                  username={usernameData}
                  categories={categories}
                  modalVisible={modalVisible}
                  searchKeyword={searchKeyword}
                  isInline={!(canClose || closable)}
                  selectedCategory={selectedCategory}
                  hideyMyformsModal={this.hideyMyformsModal}
                  displayMyformsModal={this.displayMyformsModal}
                  customTrackingProjectName={customTrackingProjectName}
                  liteModeActionButtonHandlers={liteModeActionButtonHandlers}
                  defaultSelectedCategorySlug={defaultSelectedCategorySlug || null}
                  setSelectedCategory={category => this.setState({ selectedCategory: category })}
                  setCategories={categoriesData => this.setState({ categories: categoriesData })}
                  setSearchKeyword={searchPayload => this.setState({ searchKeyword: searchPayload })}
                  setStandaloneTemplates={templates => this.setState({ standaloneTemplates: templates })}
                  handleTemplateClone={customHandleClone !== null ? customHandleClone : this.handleTemplateClone}
                />
              )
              : (
                <LegacyTemplatesRenderer
                  frameSrc={frameSrc}
                  frameRef={this.useTemplateFrame}
                  handleTemplateLoad={this.handleTemplateLoad}
                />
              )
          }
          {
            (modalVisible && newTemplateModal) && (
              <NewWizardTemplateModal
                {...modalProps}
                {...modalDetailsData}
                frameSrc={frameSrc}
                categories={categories}
                searchKeyword={searchKeyword}
                handleClose={this.hideyMyformsModal}
                useTemplate={this.handleTemplateClone}
                newStandaloneActive={newStandaloneActive}
                standaloneTemplates={standaloneTemplates}
                displayMyformsModal={this.displayMyformsModal}
                template={{ ...modalProps, ...modalDetailsData }}
                legacyStandaloneFrameRef={this.useTemplateFrame}
                setSelectedCategory={category => this.setState({ selectedCategory: category })}
                setSearchKeyword={searchPayload => this.setState({ searchKeyword: searchPayload })}
                setModalContentLoading={loading => this.setState({ modalContentLoading: loading })}
              />
            )
          }
          {
            !newTemplateModal && (
              <TemplateModal
                {...modalProps}
                {...modalDetailsData}
                source={source}
                frameSrc={frameSrc}
                username={usernameData}
                isVisible={modalVisible}
                actionLogger={actionLogger}
                onFormCreate={onFormCreate}
                onClose={this.hideyMyformsModal}
                isNewStandalone={newStandaloneActive}
                standaloneTemplates={standaloneTemplates}
                useMagnetPortal={useMagnetPortal}
                onTemplateClone={customHandleClone !== null ? customHandleClone : this.handleTemplateClone}
              />
            )
          }
        </WizardBody>
      </WizardItem>
    );
  }
}

UseTemplate.propTypes = {
  setView: func,
  onBack: func,
  username: string,
  actionLogger: func,
  onFormCreate: func,
  langPath: string,
  templatePath: string,
  categories: string,
  source: string,
  backButtonVisible: bool,
  templateHeaderText: string,
  templateHeaderSubText: string,
  teamID: string,
  hiddenHeader: bool,
  useMagnetPortal: bool,
  handleBeforeCreatingAsset: func
};

UseTemplate.defaultProps = {
  onBack: undefined,
  setView: f => f,
  onFormCreate: (id, isSalesforceTemplate = false) => handleCustomNavigation(`/build/${id}?s=templates${isSalesforceTemplate ? '&salesforceTemplate=1' : ''}`, '_self'),
  actionLogger: null,
  langPath: null,
  username: '',
  hiddenHeader: false,
  templatePath: '/form-templates/standalone',
  categories: null,
  source: 'MYFORMS',
  backButtonVisible: true,
  templateHeaderText: texts.CHOOSE_FROM_TEMPLATES,
  templateHeaderSubText: texts.CHOOSE_FROM_TEMPLATES_SUBTITLE,
  teamID: null,
  useMagnetPortal: false,
  handleBeforeCreatingAsset: null
};
