import { StorageHelper } from '@jotforminc/storage-helper';
import {
  FETCH_ENVIRONMENT,
  SET_APP_STATUS,
  TOGGLE_RIGHT_PANEL,
  TOGGLE_PREVIEW_PANEL,
  SET_MODE,
  SET_PWA_STATUS,
  TOGGLE_NETWORK_STATUS,
  SET_APP_DESIGN_MODE,
  SELECT_PORTAL_ITEM,
  SET_RIGHT_PANEL_MODE,
  TOGGLE_LEFT_PANEL,
  SET_API_REQUESTS_COMPLETED,
  SHOULD_WINDOW_SQUEEZE,
  APP_PREVIEW_STATUS,
  APP_PREVIEW_DEVICE,
  REMOVE_PORTAL_ITEMS,
  SET_APP_HEADER_CROP_MODE,
  SET_APP_COVER_CROP_MODE,
  SET_ITEM_LOADING_STATUS,
  FETCH_PORTAL,
  FETCH_FORMS,
  SET_DISABLED_APP_SCREEN,
  CREATE_NEW_PORTAL,
  FETCH_APPS,
  TOAST,
  SET_LAST_ADDED_ITEM,
  SELECT_MULTIPLE_PORTAL_ITEM,
  TOGGLE_MOBILE_MULTIPLE_SELECTION_MODE,
  SELECT_PAGES,
  UPDATE_LAST_INTERACTED_PAGE_ID,
  SET_APP_LOCATION,
  FETCH_RESOURCES_ERROR,
  SET_FORCE_TITLE_RENAME,
  ACTIVE_PAGE,
  SET_CHECKOUT_FORM_STATUS,
  SET_CHECKOUT_FORM_VISIBILITY,
  SET_ACTIVE_SCREEN,
  SET_ACTIVE_FORM_PRODUCT,
  SET_ROUTER_ANIMATION_TYPE,
  APP_TOAST,
  APP_TOAST_STACK,
  PREPARE_RIGHT_PANEL,
  TOGGLE_ACCOUNT_BOX,
  SET_ACTIVE_BUILDER_PAGE,
  SHOW_MODAL, REPLACE_FORM_ITEM,
  SET_UI_PROP,
  NAVIGATION_SETTINGS_POPOVER,
  TOGGLE_LIVE_PREVIEW
} from '../actionTypes';
import {
  APP_MODES, CHECKOUT_FORM_STATUSES, WINDOW_SQUEEZE_VALUE, UI_PROPS, NOTIFICATION_PERMISSION_STATES, APP_PREVIEW_STATES
} from '../../constants';
import { APP_DESIGN_MODES } from '../../modules/Builder/constants/designModes';
import { isPWA } from '../../modules/PublicApp/utils';
import { RightPanelModes } from '../../modules/Builder/components/HomePage/constants';
import ANIMATION_TYPES from '../../modules/PublicApp/AnimationTypes';
import { getCurrentSubTabFromURL, getCurrentTabFromURL } from '../../utils/navigation';

const initialState = {
  mode: (window.isBuilder || !window.isStandalone) === true ? APP_MODES.builder : APP_MODES.public,
  isAppReady: false,
  isOpenedInPWA: isPWA(),
  isPWAInstalled: false,
  isOnline: typeof window.navigator.onLine === 'boolean' ? window.navigator.onLine : true,
  environment: window?.JOTFORM_ENV || '',
  isRightPanelOpen: false,
  isPreviewPanelOpen: StorageHelper.getLocalStorageItem({ key: 'appBuilderIsLivePreviewOn', defaultValue: true }),
  isLivePreviewOn: StorageHelper.getLocalStorageItem({ key: 'appBuilderIsLivePreviewOn', defaultValue: true }),
  rightPanelMode: '',
  isLeftPanelOpen: false,
  lastOpenedPanel: null,
  isAiAssistantOpen: false,
  selectedForms: [],
  areAPIRequestsCompleted: true,
  selectedItems: [],
  lastAddedItemID: '',
  appDesignMode: APP_DESIGN_MODES.app,
  currentStep: getCurrentTabFromURL(),
  currentSubTab: getCurrentSubTabFromURL(),
  shouldSqueeze: global.innerWidth <= WINDOW_SQUEEZE_VALUE,
  appPreviewStatus: APP_PREVIEW_STATES.IDLE,
  appPreviewDevice: 'phone',
  isAppHeaderInCropMode: false,
  isAppCoverInCropMode: false,
  loadingItems: {},
  appsFetched: false,
  isUIBlocked: false,
  isMobileMultipleSelectionMode: false,
  selectedPages: [],
  lastInteractedPageID: '',
  appLocation: '',
  isTemplateMode: window.isTemplateMode || false,
  forceTitleRename: false,
  activePageID: '',
  navigationSettingsPopoverId: false,
  checkoutFormStatus: window.location.href.includes('complete=1') ? CHECKOUT_FORM_STATUSES.COMPLETED : CHECKOUT_FORM_STATUSES.INITIAL,
  checkoutFormVisibility: false,
  routerAnimationType: ANIMATION_TYPES.INITIAL,
  appToastStack: [],
  navigationStack: [],
  [UI_PROPS.activeAccountBoxScreen]: '',
  accountBoxStatus: false,
  [UI_PROPS.activeProductDetail]: {},
  [UI_PROPS.mobileContextMenuOpened]: false,
  activeModal: '',
  [UI_PROPS.isParagraphInlineEditMode]: false,
  [UI_PROPS.ctxMenuItemClicked]: false,
  [UI_PROPS.isGatewaySettingsDisplayed]: false,
  [UI_PROPS.addElementButtonPulseCount]: 0,
  [UI_PROPS.addElementButtonPulseVisible]: false,
  [UI_PROPS.isA2HSModalClosed]: false,
  [UI_PROPS.notificationPermissionState]: NOTIFICATION_PERMISSION_STATES.HIDDEN
};

// eslint-disable-next-line complexity
const UIReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ACCOUNT_BOX: {
      const value = action.payload;
      return {
        ...state,
        accountBoxStatus: value
      };
    }
    case FETCH_ENVIRONMENT.SUCCESS: {
      return {
        ...state, environment: action.data
      };
    }
    case SET_APP_STATUS: {
      return {
        ...state, isAppReady: action.payload === 'ready'
      };
    }
    case FETCH_APPS.SUCCESS: {
      return {
        ...state, appsFetched: true
      };
    }
    case TOGGLE_RIGHT_PANEL: {
      const lastPanel = (!action.payload && state.isLeftPanelOpen) ? 'left' : 'right';
      return {
        ...state,
        isRightPanelOpen: action.payload,
        lastOpenedPanel: lastPanel
      };
    }
    case TOGGLE_LIVE_PREVIEW: {
      return {
        ...state,
        isLivePreviewOn: action.payload
      };
    }
    case TOGGLE_PREVIEW_PANEL: {
      return {
        ...state,
        isPreviewPanelOpen: action.payload
      };
    }
    case SET_APP_DESIGN_MODE: {
      return {
        ...state, appDesignMode: action.payload
      };
    }
    case SET_MODE: {
      return {
        ...state, mode: action.payload
      };
    }
    case REPLACE_FORM_ITEM.SUCCESS: {
      const { form } = action.payload;
      return {
        ...state,
        selectedItems: [form.id]
      };
    }
    case SELECT_PORTAL_ITEM: {
      const selectedItemID = action.payload;
      if (!selectedItemID) { // Cleared
        return { ...state, selectedItems: [], isMobileMultipleSelectionMode: false };
      }

      return {
        ...state,
        selectedItems: [selectedItemID],
        selectedPages: []
      };
    }
    case SELECT_MULTIPLE_PORTAL_ITEM: {
      const {
        selection,
        withMouse
      } = action.payload;
      const { rightPanelMode } = state;
      if (withMouse) {
        return {
          ...state,
          selectedItems: selection,
          rightPanelMode: selection.length > 1 ? RightPanelModes.MULTIPLE_ITEM : rightPanelMode,
          selectedPages: []
        };
      }

      const newSelecteds = [...state.selectedItems, selection]
        .reduce((prev, cur, ind, originalArr) => {
          const idCount = originalArr.filter(x => x === cur).length;
          if (idCount > 1) { // If any id occurring more than 1, deselect it (TODO unless withRange?)
            return prev;
          }
          return [...prev, cur];
        }, []);
      return {
        ...state,
        selectedItems: newSelecteds,
        rightPanelMode: newSelecteds.length > 1 ? RightPanelModes.MULTIPLE_ITEM : RightPanelModes.APP_ITEM,
        selectedPages: []
      };
    }
    case SET_LAST_ADDED_ITEM: {
      return {
        ...state, lastAddedItemID: action.payload
      };
    }
    case SET_PWA_STATUS: {
      return {
        ...state,
        isPWAInstalled: action.isPWAInstalled,
        isOpenedInPWA: action.isOpenedInPWA
      };
    }
    case TOGGLE_NETWORK_STATUS: {
      const isOnline = !!action.payload;
      return {
        ...state, isOnline
      };
    }
    case SET_RIGHT_PANEL_MODE: {
      return {
        ...state, rightPanelMode: action.payload
      };
    }
    case PREPARE_RIGHT_PANEL: {
      return {
        ...state,
        rightPanelMode: action.payload.mode,
        selectedItems: [action.payload.item],
        selectedPages: []
      };
    }
    case SET_API_REQUESTS_COMPLETED: {
      return {
        ...state, areAPIRequestsCompleted: action.payload
      };
    }
    case TOGGLE_LEFT_PANEL: {
      const lastPanel = (!action.payload && state.isRightPanelOpen) ? 'right' : 'left';
      return {
        ...state, isLeftPanelOpen: action.payload, lastOpenedPanel: lastPanel
      };
    }
    case SHOULD_WINDOW_SQUEEZE: {
      return {
        ...state, shouldSqueeze: action.payload
      };
    }
    case APP_PREVIEW_STATUS: {
      return {
        ...state, appPreviewStatus: action.payload
      };
    }
    case APP_PREVIEW_DEVICE: {
      return {
        ...state, appPreviewDevice: action.payload
      };
    }
    case REMOVE_PORTAL_ITEMS.REQUEST: {
      const [selectedPortalItem] = state.selectedItems; // singular
      return {
        ...state,
        isRightPanelOpen: false,
        rightPanelMode: '',
        loadingItems: Object.entries(state.loadingItems).reduce((prev, [key, value]) => {
          if (key === selectedPortalItem) {
            return { ...prev };
          }
          return { ...prev, [key]: value };
        }, {})
      };
    }
    case SET_APP_HEADER_CROP_MODE: {
      return {
        ...state, isAppHeaderInCropMode: action.payload
      };
    }
    case SET_APP_COVER_CROP_MODE: {
      return {
        ...state, isAppCoverInCropMode: action.payload
      };
    }
    case SET_ITEM_LOADING_STATUS: {
      return {
        ...state,
        loadingItems: { ...state.loadingItems, ...action.payload }
      };
    }
    case FETCH_PORTAL.ERROR:
    case SET_DISABLED_APP_SCREEN:
    case CREATE_NEW_PORTAL.ERROR:
    case FETCH_FORMS.ERROR:
    case FETCH_RESOURCES_ERROR: {
      return {
        ...state,
        error: action.payload,
        customErrorText: action.customErrorText
      };
    }
    case APP_TOAST.SHOPPING_NOTIFICATION:
    case TOAST.NOTIFICATION:
    case TOAST.ERROR: {
      const { backdrop = false } = action.payload;
      return {
        ...state,
        isUIBlocked: !!backdrop
      };
    }
    case TOGGLE_MOBILE_MULTIPLE_SELECTION_MODE: {
      const mode = action.payload;
      return {
        ...state,
        isMobileMultipleSelectionMode: mode
      };
    }
    case SELECT_PAGES:
      const selectedPage = action.payload;
      return {
        ...state,
        selectedPages: [selectedPage],
        selectedItems: []
      };
    case UPDATE_LAST_INTERACTED_PAGE_ID:
      return {
        ...state,
        lastInteractedPageID: action.payload
      };
    case SET_APP_LOCATION:
      const location = action.payload;
      return {
        ...state,
        appLocation: location
      };
    case SET_FORCE_TITLE_RENAME:
      return {
        ...state,
        forceTitleRename: action.payload
      };
    case ACTIVE_PAGE:
      return {
        ...state,
        activePageID: action.payload
      };
    case SET_CHECKOUT_FORM_STATUS:
      return {
        ...state,
        checkoutFormStatus: action.payload
      };
    case SET_CHECKOUT_FORM_VISIBILITY:
      return {
        ...state,
        checkoutFormVisibility: !!action.payload
      };
    case SET_ACTIVE_SCREEN:
      return {
        ...state,
        activeScreen: action.payload
      };
    case SET_ACTIVE_FORM_PRODUCT:
      return {
        ...state,
        activeFormProduct: action.payload
      };
    case SET_ACTIVE_BUILDER_PAGE:
      return {
        ...state,
        currentStep: action.payload.tab,
        currentSubTab: action.payload.subTab
      };
    case SET_ROUTER_ANIMATION_TYPE:
      return {
        ...state,
        routerAnimationType: action.payload
      };
    case APP_TOAST_STACK.SUCCESS:
      return {
        ...state,
        appToastStack: action.payload
      };
    case SHOW_MODAL:
      return { ...state, activeModal: action.payload };
    case NAVIGATION_SETTINGS_POPOVER:
      return { ...state, navigationSettingsPopoverId: action.payload };
    case SET_UI_PROP:
      return { ...state, [action.payload.key]: action.payload.value };
    default:
      break;
  }
  return state;
};

export default UIReducer;
