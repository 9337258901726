import {
  IconProductFormBuilderFilled, IconProductFormBuilderColorBorder, IconProductSignFilled, IconProductSignColorBorder, IconLinkDiagonal, IconDocumentPdfFilled, IconDocumentXlsxFilled,
  IconDocumentDocFilled, IconDocumentImageFilled, IconDocumentSimpleFilled, IconProductTablesFilled, IconProductTablesColorBorder,
  IconProductReportBuilderFilled, IconProductReportBuilderColorBorder, IconProductInboxFilled, IconProductInboxColorBorder
} from '@jotforminc/svg-icons';
import CustomPlaceholderIcon from '../components/CustomPlaceholderIcon';
import { DOCUMENT_ITEM_TYPES, ITEM_TYPES } from './itemTypes';
import { checkMobilePhone } from '../utils';
import { LATEST_VERSION, useAppDefaults, useItemDefaults } from '../properties';
import { applyAlpha } from '../utils/styleUtils';
import { FEATURE_NAMES } from './features';
import { isFeatureEnabled } from '../utils/features/helper';
import { ItemVisualPropTypes } from './propTypes';

export const COLLABORATION = {
  TIMEOUT_SECOND: 15 * 60,
  COUNTDOWN_SECOND: 30
};

export const SHOPPING_NOTIFICATION_TYPES = {
  ADD_PRODUCT: 'add',
  DELETE_PRODUCT: 'delete',
  FAVORITE_PRODUCT: 'favorite',
  UNFAVORITE_PRODUCT: 'unfavorite'
};

export const ERROR_MESSAGES = {
  DEFAULT: 'Link is already taken.',
  reserved: 'Link is already taken.',
  notvalid: 'URL Slug may only contain letters or combination of letters, numbers, dash and underscores ("_-”).',
  alphanumeric: 'URL Slug may only contain letters or combination of letters, numbers, dash and underscores ("_-”).',
  notunique: 'Link is already taken.'
};

export const APP_MODES = {
  builder: 'APP_BUILDER',
  public: 'APP_PUBLIC'
};

export const PORTAL_ERROR_MAP = {
  FETCH: {
    404: 'DELETED',
    default: 'UNKNOWN_ERROR',
    FORM_NOT_FOUND: 'FORM_NOT_FOUND',
    builder: 'BUILDER_APP_NOT_FOUND'
  },
  DISABLED_APP: {
    default: 'DISABLED_APP'
  },
  OFFLINE: 'OFFLINE',
  CREATE: {
    CREATE_APP: 'CREATE_APP',
    CREATE_APP_RATE_LIMIT: 'CREATE_APP_RATE_LIMIT',
    CREATE_APP_RATE_LIMIT_GUEST: 'CREATE_APP_RATE_LIMIT_GUEST',
    default: 'CREATE_APP_UNKNOWN'
  },
  DELETED_APP: 'DELETED_APP'
};

// Values shouldn't be changed. They're persisted in the DB!
export const IMAGE_TYPE = {
  icon: 'LOGO/ICON',
  image: 'LOGO/IMAGE'
};

export const IMAGE_PREVIEW_REMOVE_BUTTON_TEXTS = {
  [IMAGE_TYPE.icon]: 'Remove Icon',
  [IMAGE_TYPE.image]: 'Remove Image'
};

export const FORM_PRODUCTS = { // Values are case sensitive!
  FORM: 'form',
  CONTINUE_DRAFT: 'continue',
  SENTBOX: 'sentbox',
  TABLES: 'tables',
  REPORT: 'report',
  SIGN: 'sign',
  PREVIOUS_ORDERS: 'sentbox_previousOrders',
  PREVIOUS_DONATIONS: 'sentbox_previousDonations'
};

export const SCREEN_TYPES = { // Values are case sensitive, using in URL!
  CART: 'cart'
};

export const DEFAULT_BUILDER_WIDTHS = {
  rightPanel: 470,
  mobileRightPanel: 360,
  leftPanel: 310,
  mobileLeftPanel: 300,
  main: 672
};

const {
  rightPanel, leftPanel, main, mobileLeftPanel, mobileRightPanel
} = DEFAULT_BUILDER_WIDTHS;

const rightPanelWidth = checkMobilePhone() ? mobileRightPanel : rightPanel;
const leftPanelWidth = checkMobilePhone() ? mobileLeftPanel : leftPanel;

export const WINDOW_SQUEEZE_VALUE = rightPanelWidth + leftPanelWidth + main + 40;

export const DESCRIPTION_LIMIT = 240; // TODO Acquire from commonDefinitions.json#/description.maxLength

export const APP_PREVIEW_URL_HASH = '#preview';

export const ITEM_ICONS = {
  [ITEM_TYPES.FORM]: {
    Icon: IconProductFormBuilderFilled
  },
  [ITEM_TYPES.LINK]: {
    Icon: IconLinkDiagonal
  },
  [ITEM_TYPES.TABLE_LINK]: {
    Icon: IconProductTablesFilled
  },
  [ITEM_TYPES.REPORT_LINK]: {
    Icon: IconProductReportBuilderFilled
  },
  [ITEM_TYPES.SENTBOX_LINK]: {
    Icon: IconProductInboxFilled
  },
  [ITEM_TYPES.SIGN_LINK]: {
    Icon: IconProductSignFilled
  },
  [ITEM_TYPES.DOCUMENT]: {
    [DOCUMENT_ITEM_TYPES.PDF]: {
      Icon: IconDocumentPdfFilled
    },
    [DOCUMENT_ITEM_TYPES.SHEET]: {
      Icon: IconDocumentXlsxFilled
    },
    [DOCUMENT_ITEM_TYPES.DOC]: {
      Icon: IconDocumentDocFilled
    },
    [DOCUMENT_ITEM_TYPES.IMG]: {
      Icon: IconDocumentImageFilled
    },
    [DOCUMENT_ITEM_TYPES.OTHER]: {
      Icon: IconDocumentSimpleFilled
    },
    [DOCUMENT_ITEM_TYPES.PLACEHOLDER]: {
      Icon: CustomPlaceholderIcon
    }
  }
};

export const RESOURCE_SELECTION_ICONS = {
  [ITEM_TYPES.FORM]: {
    Icon: IconProductFormBuilderColorBorder
  },
  [ITEM_TYPES.TABLE_LINK]: {
    Icon: IconProductTablesColorBorder
  },
  [ITEM_TYPES.REPORT_LINK]: {
    Icon: IconProductReportBuilderColorBorder
  },
  [ITEM_TYPES.SENTBOX_LINK]: {
    Icon: IconProductInboxColorBorder
  },
  [ITEM_TYPES.SIGN_LINK]: {
    Icon: IconProductSignColorBorder
  }
};

export const APP_LOGO_PROPS = {
  logoType: 'logoType', // svg/png
  logoColor: 'iconColor',
  logoBgColor: 'logoBackground',
  logoImageURL: 'logoURL',
  appLogoSize: 'appLogoSize'
};

export const APP_ICON_PROPS = {
  logoType: 'appIconType', // svg/png
  logoColor: 'appIconColor',
  logoBgColor: 'appIconBackground',
  logoImageURL: 'appIconURL',
  svgRef: 'appIconSvgRef'
};

export const APP_GRAPHIC_TYPES = {
  appIcon: 'appIcon',
  appLogo: 'appLogo'
};

export const APP_HEADER_PROPS = {
  appHeaderBgColor: 'appHeaderBgColor',
  appHeaderBgURL: 'appHeaderBgURL',
  appHeaderBgCropInfo: 'appHeaderBgCropInfo',
  logoURL: 'logoURL',
  name: 'name',
  description: 'description',
  appFontColor: 'appFontColor',
  appHeaderTextAlignment: 'appHeaderTextAlignment',
  appFontFamily: 'appFontFamily'
};

export const feedbackModalInfo = {
  JOTFORM_APPS: {
    formID: '201251889947972',
    featureName: 'Jotform Apps'
  },
  COPILOT: {
    formID: '242771576433967',
    featureName: 'Copilot'
  }
};

export const APP_ICON_KEYS = [
  'appIconColor', 'appIconBackground', 'appIconType', 'appIconURL', 'appIconSvgRef'
];

export const BRANDING_DEFAULT_UTM_SCHEME = {
  source: 'app',
  medium: 'banner',
  campaign: 'powered-by-jotform-remove-label'
};

export const LAYOUT_TYPES = {
  DEFAULT: 'DEFAULT',
  ALL_SHRINKED: 'ALL_SHRINKED',
  NO_ICON: 'NO_ICON',
  ALL_SHRINKED_NO_ICON: 'ALL_SHRINKED_NO_ICON',
  SHOW_ALL_FORMS: 'SHOW_ALL_FORMS',
  // MIXED: 'MIXED',
  PLAIN: 'PLAIN',
  ALL_SHRINKED_NO_HEADER: 'ALL_SHRINKED_NO_HEADER',
  LEFT_ALIGNED: 'LEFT_ALIGNED',
  RIGHT_ALIGNED: 'RIGHT_ALIGNED',
  CENTER_ALIGNED: 'CENTER_ALIGNED',
  ALL_LARGE_ELEMENT: 'ALL_LARGE_ELEMENT'
};

export const LAYOUTS_WITH_COLOR_SETTINGS = [
  LAYOUT_TYPES.PLAIN
];

export const getLayoutProps = (layoutType = LAYOUT_TYPES.DEFAULT, itemType = ITEM_TYPES.FORM) => {
  const {
    shrink,
    showItemIcon,
    itemBorderColor,
    itemBgColor,
    itemTextAlignment,
    elementSize,
    embeddedForm
  } = useItemDefaults(itemType);

  const {
    showAppHeader,
    appHeaderTextAlignment
  } = useAppDefaults(LATEST_VERSION);

  const isButtonElement = itemType === ITEM_TYPES.BUTTON;

  const relevantDefaultItemProps = {
    ...shrink && { shrink },
    ...showItemIcon && { showItemIcon },
    ...(itemBorderColor && !isButtonElement) && { itemBorderColor },
    ...(itemBgColor && !isButtonElement) && { itemBgColor },
    ...itemTextAlignment && { itemTextAlignment },
    ...elementSize && { elementSize },
    ...embeddedForm && { embeddedForm }
  };

  const defaultPortalProps = {
    showAppHeader,
    appHeaderTextAlignment
  };

  const LAYOUTS = {
    [LAYOUT_TYPES.DEFAULT]: {
      itemProps: {},
      portalProps: {}
    },
    [LAYOUT_TYPES.ALL_SHRINKED]: {
      portalProps: {},
      itemProps: { shrink: 'Yes' }
    },
    [LAYOUT_TYPES.NO_ICON]: {
      portalProps: {},
      itemProps: { showItemIcon: 'No' }
    },
    [LAYOUT_TYPES.ALL_SHRINKED_NO_ICON]: {
      itemProps: {
        showItemIcon: 'No',
        shrink: 'Yes',
        embeddedForm: 'No'
      }
    },
    [LAYOUT_TYPES.PLAIN]: {
      portalProps: { showAppHeader: 'No' },
      itemProps: {
        itemBorderColor: applyAlpha(itemBorderColor, 0),
        itemBgColor: applyAlpha(itemBgColor, 0),
        showItemIcon: 'No'
      }
    },
    [LAYOUT_TYPES.ALL_SHRINKED_NO_HEADER]: {
      portalProps: { showAppHeader: 'No' },
      itemProps: { shrink: 'Yes', embeddedForm: 'No' }
    },
    [LAYOUT_TYPES.LEFT_ALIGNED]: {
      portalProps: { appHeaderTextAlignment: 'left' },
      itemProps: { itemTextAlignment: 'left' }
    },
    [LAYOUT_TYPES.RIGHT_ALIGNED]: {
      portalProps: { appHeaderTextAlignment: 'right' },
      itemProps: { itemTextAlignment: 'right' }
    },
    [LAYOUT_TYPES.CENTER_ALIGNED]: {
      portalProps: { appHeaderTextAlignment: 'center' },
      itemProps: { itemTextAlignment: 'center' }
    },
    [LAYOUT_TYPES.ALL_LARGE_ELEMENT]: {
      portalProps: {},
      itemProps: { elementSize: 'large' }
    }
  };
  // problem here
  if (isFeatureEnabled(FEATURE_NAMES.EmbeddedForm)) {
    LAYOUTS[LAYOUT_TYPES.SHOW_ALL_FORMS] = {
      portalProps: {},
      itemProps: { embeddedForm: 'Yes', shrink: 'No' }
    };
  }

  const { itemProps: layoutItemProps, portalProps: layoutPortalProps } = LAYOUTS[layoutType] || {};

  // we only need props that exist in the item's defaults
  const filteredLayoutItemProps = Object.keys(relevantDefaultItemProps).reduce((prev, key) => {
    const value = layoutItemProps[key];
    return { ...prev, ...value && { [key]: value } };
  }, {});

  return {
    ...layoutItemProps && { itemProps: { ...relevantDefaultItemProps, ...filteredLayoutItemProps } },
    ...layoutPortalProps && { portalProps: { ...defaultPortalProps, ...layoutPortalProps } }
  };
};

export const getProtectedStylingProps = (appLayout, item) => {
  // If layout change is not related with visual props, don't change the visual props.
  const stylingProps = {};
  if (!LAYOUTS_WITH_COLOR_SETTINGS.includes(appLayout)) {
    Object.keys(ItemVisualPropTypes).forEach(prop => {
      if (item[prop]) stylingProps[prop] = item[prop];
    });
  }
  return stylingProps;
};

export const ITEM_ADDITION_ORDER_STRATEGY = {
  TO_END_OF_THE_PAGE: -1,
  MULTIPLE_ITEM_DUPLICATION: -2
};

export const CHECKOUT_FORM_STATUSES = {
  INITIAL: 'INITIAL',
  OPENED: 'OPENED',
  PAYMENT: 'PAYMENT',
  SUBMITTED: 'SUBMITTED',
  COMPLETED: 'COMPLETED',
  GATEWAY_REDIRECT_INFO: 'GATEWAY_REDIRECT_INFO'
};

export const UI_PROPS = {
  isParagraphInlineEditMode: 'isParagraphInlineEditMode',
  mobileContextMenuOpened: 'mobileContextMenuOpened',
  activeProductDetail: 'activeProductDetail',
  activeAccountBoxScreen: 'activeAccountBoxScreen',
  ctxMenuItemClicked: 'ctxMenuItemClicked',
  isGatewaySettingsDisplayed: 'isGatewaySettingsDisplayed',
  addElementButtonPulseCount: 'addElementButtonPulseCount',
  addElementButtonPulseVisible: 'addElementButtonPulseVisible',
  notificationPermissionState: 'notificationPermissionState',
  activePageID: 'activePageID',
  navigationStack: 'navigationStack'
};

export const PORTAL_TYPES = {
  APP: 'APP',
  STORE: 'STORE',
  DONATION: 'DONATION'
};

export const CHECKOUT_ITEM_PORTAL_TYPE_MAP = {
  [ITEM_TYPES.PRODUCT_LIST]: PORTAL_TYPES.STORE,
  [ITEM_TYPES.DONATION]: PORTAL_TYPES.DONATION
};

export const getPortalTypeByItems = items => {
  const item = items.find(({ type }) => [ITEM_TYPES.DONATION, ITEM_TYPES.PRODUCT_LIST].includes(type));

  return CHECKOUT_ITEM_PORTAL_TYPE_MAP[item?.type] || PORTAL_TYPES.APP;
};

export const ADD_ELELEMT_PULSE_EFFECT_MAX = 2;
export const ADD_ELELEMT_PULSE_EFFECT_LC_ST_KEY = 'appBuilderAddElementPulseSeen';

export const APP_BUILDER_TYPES = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE'
};

export const NOTIFICATION_PERMISSION_STATES = {
  VISIBLE: 'VISIBLE',
  LOADING: 'LOADING',
  HIDDEN: 'HIDDEN',
  DENIED: 'DENIED',
  ERROR: 'ERROR'
};

export const NOTIFICATION_ERROR_MESSAGES = {
  DAILY_LIMIT: 'NOTIFICATION_CAMPAIGN_DAILY_RATE_LIMIT_EXCEEDED',
  HOURLY_LIMIT: 'NOTIFICATION_CAMPAIGN_HOURLY_RATE_LIMIT_EXCEEDED',
  NOT_ALLOWED: 'NOT_ALLOWED'
};

export const NOTIFICATION_DISMISS_KEY = 'appPushNotificationDismissed';

export const APP_PREVIEW_STATES = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  READY: 'READY'
};

export const LIVE_PREVIEW_SIZE_MODES = {
  FIT_HEIGHT: 'fitHeight',
  ACTUAL_SIZE: 'actualSize'
};
