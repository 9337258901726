/* eslint-disable max-lines */
/* eslint-disable camelcase */
import React from 'react';
import {
  actionChannel, all, call, delay, put, select, spawn, take, takeEvery, takeLatest, debounce,
  fork, race
} from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import difference from 'lodash/difference';
import isUndefined from 'lodash/isUndefined';
import { navigate } from '@reach/router';
import { sumMoney } from '@jotforminc/money-utils';
import { safeJSONParse, handleCustomNavigation } from '@jotforminc/utils';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { prepareIcons } from '@jotforminc/icon-selector';
import { t } from '@jotforminc/translation';
import { StorageHelper } from '@jotforminc/storage-helper';
import Tracking from '@jotforminc/tracking';
import { getAppPath } from '@jotforminc/router-bridge';
import { PushManager, SubscriptionError } from '@jotforminc/push-notification';
import { PaymentActions } from '@jotforminc/payment-settings-editor';
import Moment from 'moment';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import * as ACTION_TYPES from '../actionTypes';
import * as API from '../../modules/api';
import SELECTORS from '../selectors';
import * as ACTION_CREATORS from '../actionCreators';
import {
  ADD_ELELEMT_PULSE_EFFECT_LC_ST_KEY,
  ADD_ELELEMT_PULSE_EFFECT_MAX,
  APP_HEADER_PROPS,
  APP_MODES,
  APP_PREVIEW_STATES,
  BRANDING_DEFAULT_UTM_SCHEME,
  CHECKOUT_FORM_STATUSES,
  ERROR_MESSAGES,
  getLayoutProps,
  getProtectedStylingProps,
  IMAGE_TYPE,
  ITEM_ADDITION_ORDER_STRATEGY,
  NOTIFICATION_DISMISS_KEY,
  NOTIFICATION_ERROR_MESSAGES,
  NOTIFICATION_PERMISSION_STATES,
  PORTAL_ERROR_MAP,
  UI_PROPS
} from '../../constants';
import {
  captureException,
  checkMobilePhone,
  getUserAgentPlatformType,
  getTeamID,
  getUpdatedDate,
  ignoredActions,
  isItemTypeWidget,
  isTestingEnv,
  isTeamResourcePicker,
  isYes,
  sanitizeHTML,
  sendBreadcrumbsToSentry,
  sendTrackData,
  updateAndOverrideButtonProperties,
  utmParser,
  checkAndAddProtocolToTargetLink,
  isValidLinkTarget,
  isIosSafari
} from '../../utils';
import { getOrderedItemList, safeWorker, sanitizeSVGIconURLs } from '../utils';
import { generateResourceURL } from '../../utils/navigation';
import { isPWA } from '../../modules/PublicApp/utils';
import { ITEM_TYPES, RESOURCE_TYPES } from '../../constants/itemTypes';
import {
  getAvailableItemTypesByVersion,
  getLatestVersion,
  guardPropsForGrandfatheredApps,
  isAppGrandfathered,
  useAppDefaults,
  useItemDefaults
} from '../../properties';
import { FEATURE_NAMES } from '../../constants/features';
import { isFeatureEnabled, useEnabledFeatures } from '../../utils/features/helper';
import { watchUndoableActions, watchUndoRedoActions } from '../watchers/undoRedo';
import {
  checkAddElementPulseVisible,
  initAppElementPanelAbTest,
  initAppNameIconModalAbTest,
  keepClosedUIPanels,
  watchMultipleSelection,
  watchSelectAllItems,
  watchUIPanelsChanges,
  watchUIupdates,
  watchWindowSqueeze
} from './ui';
import { watchToastActions } from '../watchers/toast';
import { watchNetworkStatus } from '../watchers/network';
import { watchItemDuplication } from '../watchers/itemDuplication';
import { watchDoneItemProgress, watchRestartProgress, watchTodoItemProgress } from '../watchers/progress';
import {
  watchFetchShareList,
  watchResourceShareURLUpdate,
  watchShareDeletePortal,
  watchSharePortal
} from '../watchers/share';
import VERSIONS from '../../properties/versions';
import { watchStylingActions } from '../watchers/styling';
import { watchProgressBarAvailability } from '../workers/progressBarAvailability';
import { pageActions, watchHeadingItemToPageNaming, watchPageUpdate } from '../watchers/pageActions';
import { handleInstallableAppIcon, watchInstallableIconBuilderFlow } from '../watchers/assetGeneration';
import { watchPortalOrderInItemAddition } from '../workers/portalOrder';
import appConfig from '../../constants/appConfig';
import { watchItemSorting } from '../workers/itemSorting';
import { BUTTON_ROLE_TYPES } from '../../modules/Builder/components/HomePage/RightPanel/ButtonActions/buttonRoleTypes';
import { watchModals } from '../watchers/modals';
import { watchSignupActions } from '../watchers/signup';
import { resourceLinkTypes, resourceTypeMap, RightPanelModes } from '../../modules/Builder/components/HomePage/constants';
import productListActions from '../watchers/productListActions';
import navigationActions from '../watchers/navigation';
import collaborationFlow from '../watchers/collaboration';
import { momentToString } from '../../modules/Builder/components/Settings/utils';
import watchAppToast from '../watchers/appToast';
import searchInProductsActions from '../watchers/filterProducts';
import { MODALS } from '../../constants/modals';
import { TEAM_ID } from '../../constants/team';
import { DESTINATION_TYPES } from '../../constants/navigation';
import eventsFlow from '../events';
import { AllWidgetIDs } from '../../constants/availableWidgets';
import { activateFullStoryOnTheFly } from '../watchers/fullstory';
import { getColoredPropertiesBySchemeID } from '../../properties/styling';
import { getFilteredProperties } from '../../modules/Builder/components/HomePage/MultipleRightPanel/utils';
import productList from './productList';
import aiAssitant from './aiAssistant';
import checkoutFormFlow from './checkoutForm';
import dataSourceFlow, { dsFetchColumnsFlow } from './dataSource/dataSource';
import { eventChannelRegistry, resultMutaters, shouldUpdatePropFromResponse } from './helper';
import { abTestActionLoggerSingleton, AB_TEST_NAMES, LC_KEYS } from '../../utils/AbTestActionLoggerSingleton';
import ActionHelper from '../../utils/ActionsHelper';
import { AB_TESTS, logAbTestActionFor } from '../../utils/abtests';
import { AVAILABLE_DETAIL_PAGE_ITEMS } from '../../modules/Builder/components/HomePage/RightPanel/dataSourceHelpers';
import { isPushPermissionRecentlyDismissed } from '../../modules/PublicApp/PushNotificationPermission/utils';
import { DS_ITEM_LOAD_TYPES } from '../reducers/dataSource/constants';
import { WHATS_NEW_MODAL_FEATURES } from '../../modules/Builder/components/Modals/WhatsNewModal';
import { publishNavPaths } from '../../modules/Builder/components/Publish/constants';

const isUpdateAPIAction = type => /(UPDATE|ADD|REMOVE).*\/(REQUEST|SUCCESS)/.test(type);
const isAPISuccessAction = ({ type }) => /SUCCESS$/.test(type);
const isAPIRequestAction = ({ type }) => /(REQUEST|UNDOABLE)$/.test(type);
const isAPIErrorAction = ({ type }) => /ERROR$/.test(type); // eslint-disable-line no-unused-vars

export function* fetchUserFlow() {
  let currentUser = yield select(SELECTORS.getUser);
  let payload;
  if (currentUser.username) {
    // We already got it from index.php
    delete window?.__userInfo;
    payload = { credentials: currentUser, type: currentUser.USER_TYPE };
  } else {
    // we should've got the user.. It seems something unexpected occurred let's try one last time for the sentry :D
    yield put({ type: ACTION_TYPES.FETCH_USER.REQUEST });
    try {
      const withCred = yield call(API.fetchUser);
      payload = withCred;
      currentUser = withCred.credentials;
    } catch (e) {
      console.error('Error on fetch user:', e);
      // Mocking since no valid user found at session.
      // The show must go on..
      const mockPublicUser = {
        type: 'USER',
        credentials: {
          username: 'guest_apiDemo',
          name: null,
          account_type: 'GUEST',
          allowMyApps: true,
          time_zone: ''
        }
      };
      payload = mockPublicUser;
    }
  }

  yield spawn(watchSignupActions);

  const { username, name } = currentUser;
  yield put({ type: ACTION_TYPES.FETCH_USER.SUCCESS, payload });

  if (Tracking.FSisInitialized()) {
    Tracking.identify(username, {
      displayName: name || username,
      appBuilder_bool: true
    });
  }
}

export function* watchFetchPortalSuccess({ payload: { checkoutFormID } }) {
  const isBuilder = yield select(SELECTORS.getIsBuilder);
  if (isBuilder && checkoutFormID) {
    yield put({ type: ACTION_TYPES.FETCH_CHECKOUT_FORM_QUESTIONS.REQUEST, payload: checkoutFormID });
  }
}

export function* fetchWidgetsFlow() {
  const appMode = yield select(SELECTORS.getAppModeSelector);
  if (appMode === APP_MODES.builder) {
    let { username } = yield select(SELECTORS.getUser);
    if (window.isDevelopment && window.DEVELOPER) {
      username = window.DEVELOPER;
    }
    yield put({ type: ACTION_TYPES.FETCH_WIDGETS.REQUEST });
    try {
      const allWidgets = yield call(API.fetchWidgets, username);
      const filterUsedWidgets = allWidgets.filter(widget => AllWidgetIDs.includes(widget?.client_id));
      yield put({ type: ACTION_TYPES.FETCH_WIDGETS.SUCCESS, payload: filterUsedWidgets });
    } catch (exception) {
      console.error(exception);
      yield put({ type: ACTION_TYPES.FETCH_WIDGETS.ERROR });
    }
  }
}

export function* fetchEnvironmentFlow() {
  let environment = yield select(SELECTORS.getEnvironmentSelector);
  if (!environment) {
    yield put({ type: ACTION_TYPES.FETCH_ENVIRONMENT.REQUEST });
    environment = yield call(API.fetchEnvironmentVariables);
  }

  yield put({ type: ACTION_TYPES.FETCH_ENVIRONMENT.SUCCESS, data: environment });
}

const getNewMigrationProps = (fromVersion, incomingData) => {
  // WORKS FOR 0 Only
  const {
    logoURL, logoType, logoBackground, iconColor
  } = incomingData;
  const latestDefaults = useAppDefaults(getLatestVersion());
  const defaultsByVersion = useAppDefaults(fromVersion);
  const keysByVersion = Object.keys(defaultsByVersion);
  const reducedLatests = Object.entries(latestDefaults).reduce((prev, [key]) => {
    return keysByVersion.includes(key)
      ? { ...prev }
      : { ...prev, [key]: '' }; // This prop don't know by the app. Builder is more capable than app. Should be neutralized.
  }, {});
  const downgradedDefaults = { ...defaultsByVersion, ...reducedLatests };
  if (!downgradedDefaults.appIconURL
    || !downgradedDefaults.appIconType
    || !downgradedDefaults.appIconBackground
    || !downgradedDefaults.appIconColor
  ) {
    // TODO exception for appIcon? handle upward and downward migrations all together! Consider items too..
    downgradedDefaults.appIconURL = logoURL;
    downgradedDefaults.appIconType = logoType;
    downgradedDefaults.appIconBackground = logoBackground;
    downgradedDefaults.appIconColor = iconColor;
  }

  return {
    ...downgradedDefaults,
    ...guardPropsForGrandfatheredApps
  };
};

const getMigratedItems = ({ items, fromVersion }) => {
  // TODO Possible irem type conversion point!
  return items.map(item => {
    const itemDefaults = useItemDefaults(item.type, fromVersion);
    const {
      itemBorderColor: defaultItemBorderColor,
      itemBgColor: defaultItemBgColor,
      itemFontColor: defaultItemFontColor,
      itemTextAlignment: defaultItemTextAlignment
    } = itemDefaults;

    const {
      itemBorderColor = defaultItemBorderColor,
      itemBgColor = defaultItemBgColor,
      itemFontColor = defaultItemFontColor,
      itemTextAlignment = defaultItemTextAlignment
    } = item;

    return {
      itemID: item.id,
      itemBorderColor,
      itemBgColor,
      itemFontColor,
      itemTextAlignment
    };
  });
};

function* checkIsAssetBusy({ id: appID, ...props }) {
  const isBuilder = yield select(SELECTORS.getIsBuilder);
  if (!getTeamID() || !isBuilder) return false;
  const result = yield call(API.getAssetIsBusy, appID);
  if (isEmpty(result)) {
    yield call(API.teamLog, appID, 'portalAccessed');
    return false;
  }
  yield put(ACTION_CREATORS.updatePortalPropAction({ editingResourceProps: result, ...props }));
  return true;
}

// eslint-disable-next-line max-statements
function* normalizeAndRegisterApp(appProps) {
  const latestVersion = getLatestVersion();
  const appMode = yield select(SELECTORS.getAppModeSelector);
  const appDefaultsByVersion = useAppDefaults(latestVersion);

  const {
    appVersion, id: portalID,
    name, appBgColor, appBgColorEnd,
    items
  } = appProps;

  // BUGFIX #3828940 :: We should clear HTML from the title
  const titleDOM = document.createElement('div');
  titleDOM.innerHTML = sanitizeHTML(appProps.title);
  const title = titleDOM.textContent || titleDOM.innerText || '';

  const {
    appBgColor: latestDefaultAppBgColor
  } = appDefaultsByVersion;

  let newProps = { ...appProps, title }; // hope it stays 1 level

  // we need to update some properties while Fetching portals silently
  const normalizeProperties = {
    name: {
      condition: isUndefined(name),
      replace: name || title
    },
    appBgColorEnd: {
      condition: appBgColor && isUndefined(appBgColorEnd),
      replace: appBgColor
    }
  };

  const propertyKeys = Object.keys(normalizeProperties);
  let propertiesToUpdate = {};
  propertyKeys.forEach(property => {
    const element = normalizeProperties[property];
    if (element.condition) {
      propertiesToUpdate = { ...propertiesToUpdate, [property]: element.replace };
    }
  });

  newProps = { ...newProps, ...propertiesToUpdate };

  let migratedItems = {};
  if (isAppGrandfathered(appVersion)) {
    // Handle App
    const reducedProps = getNewMigrationProps(appVersion, newProps);
    // Downgrade defaults by neutralizing for grandfathered apps (closed appCover, transparent appHeaderBgColor etc.)
    newProps = {
      ...reducedProps,
      ...newProps
    };

    const migratedPortal = {
      ...guardPropsForGrandfatheredApps,
      appVersion: getLatestVersion()
    };

    const hasCustomBgColor = Object.keys(appProps).includes('appBgColor');
    if (!hasCustomBgColor) {
      Object.assign(migratedPortal, {
        appBgColor: latestDefaultAppBgColor,
        appBgColorEnd: latestDefaultAppBgColor // for now, it is not saved
      });
    }

    newProps = { ...newProps, ...migratedPortal };
    propertiesToUpdate = { ...propertiesToUpdate, ...migratedPortal };
    // Handle Items
    migratedItems = getMigratedItems({ items, fromVersion: appVersion });
  }

  if (yield checkIsAssetBusy(newProps)) return;

  // one and only!
  yield put({ type: ACTION_TYPES.FETCH_PORTAL.SUCCESS, payload: { ...newProps } });

  if (appMode === APP_MODES.builder) {
    const hasPropToUpdate = !!Object.keys(propertiesToUpdate).length;
    if (hasPropToUpdate) yield call(API.updatePortal, portalID, { ...propertiesToUpdate, systemAction: 1 });

    const hasItemsToMigrate = !!Object.keys(migratedItems).length;
    if (hasItemsToMigrate) {
      for (let i = 0; i < migratedItems.length; i++) {
        const item = migratedItems[i];
        yield put(ACTION_CREATORS.updateItemPropAction({ itemID: item.itemID, prop: item }));
      }
    }
  }

  if (appMode === APP_MODES.public) {
    yield put(ACTION_CREATORS.calculateDoneItemCount());
    yield put(ACTION_CREATORS.calculateTodoItemCount());
  }
}

export function* fetchPortalFlow(props = {}) {
  const hasPortalInfo = typeof window.__appInfo === 'object' && window.__appInfo.id;
  const { portalID } = props;
  if (!portalID && !hasPortalInfo) return; // BuildWithForm
  const appMode = yield select(SELECTORS.getAppModeSelector);
  const endpoints = {
    [APP_MODES.public]: API.fetchPortalPublicInfo,
    [APP_MODES.builder]: API.fetchPortal
  };
  try {
    const currentApp = yield (hasPortalInfo ? window.__appInfo : call(endpoints[appMode], portalID));
    yield call(normalizeAndRegisterApp, currentApp);
    delete window.__appInfo;
  } catch (error) {
    const status = error?.response?.status;
    const errorMap = appMode === APP_MODES.builder ? 'builder' : status;
    yield put({ type: ACTION_TYPES.SET_APP_STATUS, payload: { error } });
    yield put({ type: ACTION_TYPES.FETCH_PORTAL.ERROR, payload: PORTAL_ERROR_MAP.FETCH[errorMap] || PORTAL_ERROR_MAP.FETCH.default, error });
  }
}

export function* fetchShareInfoFlow() {
  try {
    yield put({ type: ACTION_TYPES.FETCH_SHARE_INFO.REQUEST });
    const appMode = yield select(SELECTORS.getAppModeSelector);
    if ((appMode === APP_MODES.builder) && window.__shareInfo) {
      const resourceShareInfo = (typeof window.__shareInfo === 'object' && window.__shareInfo) ? window.__shareInfo : { shareList: [], shareToken: '' };
      yield put({ type: ACTION_TYPES.FETCH_SHARE_LIST.SUCCESS, payload: Object.values(resourceShareInfo.shareList) });
      yield put({ type: ACTION_TYPES.UPDATE_RESOURCE_SHARE_URL.SUCCESS, payload: resourceShareInfo.shareToken });
      delete window.__shareInfo;
    } else if ((appMode === APP_MODES.builder) && !window.__shareInfo) {
      yield put({ type: ACTION_TYPES.FETCH_SHARE_LIST.REQUEST });
    }
    yield put({ type: ACTION_TYPES.FETCH_SHARE_INFO.SUCCESS });
  } catch (error) {
    yield put({ type: ACTION_TYPES.SET_APP_STATUS, payload: { error } });
    yield put({ type: ACTION_TYPES.FETCH_SHARE_INFO.ERROR, payload: { error } });
  }
}

function* withPrefetchedData(key, ACTION) {
  if (typeof window[key] === 'object' && window[key]) {
    yield put({ type: ACTION.SUCCESS, payload: window[key] });
    delete window[key];
  } else {
    yield put({ type: ACTION.REQUEST });
  }
}

function* fetchStorePropertiesFlow() {
  const isPaymentApp = yield select(SELECTORS.getIsPaymentApp);
  const isBuilder = yield select(SELECTORS.getIsBuilder);
  if (!isBuilder && isPaymentApp) {
    yield withPrefetchedData('__storeProperties', ACTION_TYPES.FETCH_STORE_PROPERTIES);
  }
}

export function* fetchTeamFlow() {
  const isBuilder = yield select(SELECTORS.getIsBuilder);
  if (isBuilder) {
    yield withPrefetchedData('__userTeams', ACTION_TYPES.FETCH_USER_TEAMS);
    yield withPrefetchedData('__userPermissionsInfo', ACTION_TYPES.FETCH_USER_TEAM_PERMISSIONS);
  }
}

export function* appStatusChecker() {
  const appMode = yield select(SELECTORS.getAppModeSelector);
  if (appMode === APP_MODES.builder) {
    yield all([
      take(ACTION_TYPES.FETCH_PORTAL.SUCCESS),
      take(ACTION_TYPES.FETCH_ENVIRONMENT.SUCCESS),
      take(ACTION_TYPES.FETCH_USER.SUCCESS),
      take(ACTION_TYPES.FETCH_WIDGETS.SUCCESS)
    ]);
  } else {
    yield all([
      take(ACTION_TYPES.FETCH_PORTAL.SUCCESS),
      take(ACTION_TYPES.FETCH_USER.SUCCESS)
    ]);
  }
  yield put({ type: ACTION_TYPES.SET_APP_STATUS, payload: 'ready' });
}

function* trackEvent({ payload: { action = '', target = {} } } = {}) {
  if (!action) return;
  if (ignoredActions.includes(action)) return;

  if (typeof target === 'object') {
    /* eslint-disable no-param-reassign */
    target.device = getUserAgentPlatformType();
    target.isPWA = isPWA();
    /* eslint-enable no-param-reassign */
  }

  const username = yield select(SELECTORS.getUsername);
  const portalID = yield select(SELECTORS.getPortalIDSelector);
  const portalType = yield select(SELECTORS.getPortalType);
  yield call(sendTrackData, username, action, { portalID, portalType, ...target });
}

function* trackEvents() {
  yield takeEvery(ACTION_TYPES.TRACK_EVENT, trackEvent);
}

function* watchErrors({ type, error }) { // eslint-disable-line require-yield
  sendBreadcrumbsToSentry({
    level: 'error', type: 'error', category: 'actions', message: `Error occured type: ${type}`
  });
  yield call(captureException, error);
}

// TODO :: seperate this
/* eslint-disable max-statements */
export function* watchPortalUpdates(action) {
  const { payload: properties } = action;
  const portalID = yield select(SELECTORS.getPortalIDSelector);
  const appVersion = yield select(SELECTORS.getAppVersionSelector);

  const keysToUpdate = Object.keys(properties);
  const defaultAppByVersion = useAppDefaults(appVersion);
  const defaultsKeysByVersion = Object.keys(defaultAppByVersion);
  const defaultAppByLatest = useAppDefaults(getLatestVersion());
  const defaultsKeysByLatest = Object.keys(defaultAppByLatest);

  const migrationRequiredKeys = difference(defaultsKeysByLatest, defaultsKeysByVersion);
  const shouldMigrate = keysToUpdate.some(key => migrationRequiredKeys.includes(key));
  const finalVersion = shouldMigrate ? getLatestVersion() : appVersion;

  const resetCropTriggers = {
    appCoverBgURL: 'appCoverBgCropInfo',
    appHeaderBgURL: 'appHeaderBgCropInfo'
  };

  const updatingProperties = Object.entries(properties).reduce((prev, [key]) => {
    return Object.keys(resetCropTriggers).includes(key)
      ? {
        ...prev,
        [resetCropTriggers[key]]: ''
      }
      : { ...prev };
  }, properties);

  // Removing appLogo should reset AppLogoSize too..
  if (updatingProperties.logoURL === '') {
    updatingProperties.appLogoSize = 0;
  }

  // title
  if (updatingProperties.name) {
    const title = yield select(SELECTORS.getAppTitle);
    const name = yield select(SELECTORS.getAppName);
    const { title: defaultAppTitle } = defaultAppByVersion;
    if (defaultAppTitle === title || title === name) {
      updatingProperties.title = updatingProperties.name;
    }
  }

  // attach version
  let newVersionProps = { appVersion: finalVersion };
  const currentApp = yield select(SELECTORS.getAppInfoWithDefaults);
  if (shouldMigrate) {
    const initialMigrationProps = getNewMigrationProps(finalVersion, currentApp);
    newVersionProps = { ...initialMigrationProps, ...currentApp, appVersion: finalVersion };
  }
  const propsToUpdate = { ...updatingProperties, ...newVersionProps };
  const currentData = Object.keys(propsToUpdate).reduce((pre, next) => {
    return { ...pre, [next]: currentApp[next] };
  }, {});

  if (propsToUpdate.mobileMenuIcon) {
    propsToUpdate.mobileMenuIcon = sanitizeSVGIconURLs(propsToUpdate.mobileMenuIcon);
  }

  yield put({
    type: ACTION_TYPES.UPDATE_PORTAL.REQUEST,
    payload: propsToUpdate
  });

  const updateResult = yield call(API.updatePortal, portalID, propsToUpdate);
  yield put(ACTION_CREATORS.trackEventAction({ action: 'appUpdated', target: { props: Object.keys(updatingProperties) } }));
  if (shouldMigrate) {
    yield put(ACTION_CREATORS.trackEventAction({ action: 'appMigrated', target: { to: finalVersion } }));
  }

  // make mode on crop state while changing AppHeader/Cover background
  const backgroundCropProps = Object.keys(resetCropTriggers);
  const updatingBackgroundCropProp = Object.keys(updateResult).filter(prop => backgroundCropProps.includes(prop))[0];
  if (updatingBackgroundCropProp && !!updateResult[updatingBackgroundCropProp] && !checkMobilePhone()) {
    yield put(ACTION_CREATORS.toggleRightPanelAction(false));
    yield put(ACTION_CREATORS.toggleImageCropState(updatingBackgroundCropProp, true));
  }

  // close RightPanel after AppHeader is closed
  const isClosingAppHeader = propsToUpdate?.openAppHeader === 'No';
  if (isClosingAppHeader) {
    yield put(ACTION_CREATORS.setRightPanelModeAction(''));
    yield put(ACTION_CREATORS.selectPortalItemAction());
  }

  yield put({
    type: ACTION_TYPES.UPDATE_PORTAL.SUCCESS,
    payload: updateResult,
    requestAction: action,
    currentData
  });
}

function* fetchCheckoutFormID() {
  const appID = yield select(SELECTORS.getAppID);

  yield put(ACTION_CREATORS.fetchPortalAction(appID));
}

// eslint-disable-next-line complexity
export function* watchPortalItemAdditions(action) {
  const logAbTestAction = abTestActionLoggerSingleton[AB_TEST_NAMES.ELEMENT_PANEL_VISIBILITY] || (f => f);
  const getItemDefaults = yield select(SELECTORS.getItemDefaultsGetter);

  const isElementAlreadyAdded = StorageHelper.getLocalStorageItem({ key: LC_KEYS.ELEMENT_PANEL_VISIBILITY.IS_ELEMENT_ADDED });
  if (!isElementAlreadyAdded) {
    StorageHelper.setLocalStorageItem({ key: LC_KEYS.ELEMENT_PANEL_VISIBILITY.IS_ELEMENT_ADDED, value: true });
    logAbTestAction(
      {
        action: 'click',
        target: 'appElementAddedFirstTime'
      }
    );
  }

  const {
    payload: {
      items, order, isDuplicate = false, focusItemID
    }, dontStack = false, isUndo = false
  } = action;
  if (Array.isArray(items) && items.length === 0) {
    return;
  }
  const portalID = yield select(SELECTORS.getPortalIDSelector);

  // Considers the page of the LAST item in the payload in order to cover the case of adding into different pages (caused by an undo of multiple delete)
  const pageOfTheItem = items.reduce((prev, { page = 'homepage' }) => page, '');

  if (!focusItemID) {
    yield put(ACTION_CREATORS.updateLastInteractedPageIDAction(pageOfTheItem));
  }

  let overridedItems = items;
  // Override w/selected scheme/theme
  if (!isDuplicate) {
    const { overridingItemProps } = yield select(SELECTORS.getAppInfoWithDefaults);
    const overridingProps = safeJSONParse(overridingItemProps);
    overridedItems = items.map(i => {
      const { type } = i;
      if (!isEmpty(overridingProps) && Object.keys(overridingProps).includes('itemColors')) {
        return { ...i, ...type === ITEM_TYPES.BUTTON ? overridingProps.buttonColors : overridingProps.itemColors };
      }
      return { ...i, ...type === ITEM_TYPES.BUTTON ? {} : overridingProps };
    });
  }

  overridedItems = overridedItems.map(item => {
    switch (item.type) {
      case ITEM_TYPES.LIST: {
        const { presentationItemType } = item;
        const presentationItem = getItemDefaults(presentationItemType);
        return { ...item, presentationItem };
      }
      default: {
        return item;
      }
    }
  });

  const result = yield call(API.addItemToPortal, portalID, overridedItems, Math.round(order));

  const listItems = result.filter(item => item.type === ITEM_TYPES.LIST);

  if (listItems.length > 0 && !isUndo) {
    yield all(
      listItems.map(item => [
        put(ACTION_CREATORS.createAndLinkDetailsPage({ itemID: item.id, resourceID: item.resourceID, viewID: item.viewID })),
        put(ACTION_CREATORS.dsSetLoading({ itemID: item.id, loadType: DS_ITEM_LOAD_TYPES.INIT, isLoading: true }))
      ]).flat()
    );
  }

  const hasCheckoutRelatedItem = result.some(({ type }) => [ITEM_TYPES.PRODUCT_LIST, ITEM_TYPES.DONATION].includes(type));
  if (hasCheckoutRelatedItem) yield fetchCheckoutFormID();

  yield put({
    type: ACTION_TYPES.ADD_PORTAL_ITEMS.SUCCESS, payload: result, requestAction: action, dontStack
  });

  // manage pulse effect
  const pulseStorage = StorageHelper.getLocalStorageItem({ key: ADD_ELELEMT_PULSE_EFFECT_LC_ST_KEY });
  const currentPulseCount = yield select(SELECTORS.getUIProp(UI_PROPS.addElementButtonPulseCount));
  if (!pulseStorage && (currentPulseCount < ADD_ELELEMT_PULSE_EFFECT_MAX)) {
    const nextPulseCount = currentPulseCount + 1;
    yield put(ACTION_CREATORS.setUIProp(UI_PROPS.addElementButtonPulseCount, nextPulseCount));
    if (nextPulseCount === ADD_ELELEMT_PULSE_EFFECT_MAX) {
      yield put(ACTION_CREATORS.setUIProp(UI_PROPS.addElementButtonPulseVisible, false));
      StorageHelper.setLocalStorageItem({
        key: ADD_ELELEMT_PULSE_EFFECT_LC_ST_KEY,
        value: true
      });
    }
  }

  const isLeftPanelOpen = yield select(SELECTORS.isLeftPanelOpenSelector);
  const isMobile = checkMobilePhone();
  if (isLeftPanelOpen && isMobile) {
    yield put(ACTION_CREATORS.toggleLeftPanelAction(false));
  }

  // make mobile multiple selection mode false while adding new item
  const isMobileMultiSelectedMode = yield select(SELECTORS.getSelectedMultipleItems);
  if (isMobileMultiSelectedMode) {
    yield put(ACTION_CREATORS.toggleMultipleSelectionModeAction(false));
  }

  // User may trying to add a "newer" item type. That requires migration.
  const appVersion = yield select(SELECTORS.getAppVersionSelector);
  const availableTypesByVersion = getAvailableItemTypesByVersion(appVersion);
  const addedSomeNewItemType = items.some(({ type }) => !availableTypesByVersion.includes(type)); // a "newer" item is being added
  const shouldMigrate = appVersion < VERSIONS[1] && addedSomeNewItemType;
  if (shouldMigrate) {
    yield put(ACTION_CREATORS.updatePortalAction({ appVersion: getLatestVersion() }));
  }

  const additionResult = result.filter(({ type }) => !!type); // Result with no type means order update as a side effect of addition
  for (let i = 0; i < additionResult.length; i++) {
    const { type, portalOrder, id } = additionResult[i];
    const eventAction = `${type === ITEM_TYPES.FORM ? 'formsAdded' : `${type.toLowerCase()}Added`}`;
    const details = { portalOrder, id };
    const target = type === ITEM_TYPES.FORM ? { ...details, count: 1 } : details;
    yield put(ACTION_CREATORS.trackEventAction({ action: eventAction, target }));
  }

  // Treat last added item
  const [{ id: lastAddedItemID, type }] = listItems.length > 0 ? listItems.slice(-1) : additionResult.slice(-1);
  if (isLeftPanelOpen && isMobile) {
    yield delay(300);
  }

  const settedLastAddedItem = focusItemID ?? lastAddedItemID;

  yield put({ type: ACTION_TYPES.SET_LAST_ADDED_ITEM, payload: settedLastAddedItem });

  const isWidget = type === ITEM_TYPES.WIDGET;
  const targetRightPanelMode = isWidget ? RightPanelModes.APP_WIDGET : RightPanelModes.APP_ITEM;
  yield put(ACTION_CREATORS.prepareRightPanelAction(targetRightPanelMode, settedLastAddedItem));
  if ([ITEM_TYPES.PRODUCT_LIST, ITEM_TYPES.DONATION].includes(type)) {
    yield put(ACTION_CREATORS.toggleLeftPanelAction(false));
    yield put(ACTION_CREATORS.toggleRightPanelAction(true));
  }
}

export function* watchPortalItemRemovals(action) {
  const { payload: { itemIDs }, dontStack = false } = action;
  const portalID = yield select(SELECTORS.getPortalIDSelector);
  const existingItems = yield select(SELECTORS.getPortalItems);
  const itemIDList = itemIDs.filter(i => i);
  const itemsToRemove = existingItems.filter(({ id }) => itemIDList.includes(id));
  const formsToRemoveIds = itemsToRemove.filter(({ type }) => type === ITEM_TYPES.FORM).map(({ id }) => id);
  const sentboxItems = existingItems.filter(({ type }) => type === ITEM_TYPES.SENTBOX_LINK);

  if (formsToRemoveIds && sentboxItems) {
    const sentboxItemsToRemove = sentboxItems.filter(({ resourceID }) => formsToRemoveIds.includes(resourceID));
    sentboxItemsToRemove.forEach(sentboxItem => {
      itemsToRemove.push(sentboxItem);
      itemIDList.push(sentboxItem.id);
    });
  }

  if (!itemIDList.length) {
    throw new Error('No item ID supplied for removal.');
  }

  let newItemList = existingItems;

  itemsToRemove.forEach(item => {
    newItemList = getOrderedItemList(newItemList, item);
  });

  yield call(API.removeItemsFromPortal, portalID, itemIDList);
  const isSingleItem = itemsToRemove.length === 1;
  yield put(ACTION_CREATORS.trackEventAction(
    {
      action: 'itemsRemoved',
      target: {
        count: itemIDList.length,
        ...isSingleItem && { type: itemsToRemove[0].type, id: itemsToRemove[0].id }
      }
    }
  ));

  const isPaymentFieldDeleted = itemsToRemove.find(item => [ITEM_TYPES.PRODUCT_LIST, ITEM_TYPES.DONATION].includes(item.type));
  const noPaymentAfterDeletion = !newItemList.find(item => [ITEM_TYPES.PRODUCT_LIST, ITEM_TYPES.DONATION].includes(item.type));
  const isReusableConnectionEnabled = yield select(SELECTORS.getIsReusableConnectionEnabled);
  if (isPaymentFieldDeleted && noPaymentAfterDeletion && isReusableConnectionEnabled) { // remove/detach connection
    const checkoutFormID = yield select(SELECTORS.getCheckoutFormIDSelector);
    yield put(ACTION_CREATORS.updateGatewaySettings({ type: 'control_payment', currency: 'USD', itemDeleted: 1 }));
    yield call(PaymentActions.detachPaymentConnection, 'APP', checkoutFormID);
  }

  yield put({
    type: ACTION_TYPES.REMOVE_PORTAL_ITEMS.SUCCESS, payload: newItemList, requestAction: action, dontStack, currentData: itemsToRemove
  });

  const message = isSingleItem
    ? t('Item is deleted')
    : t('Items are deleted');

  yield put(ACTION_CREATORS.undoableToastAction(message));
  yield put(ACTION_CREATORS.selectPortalItemAction(''));
}

export function* watchFetchForms() {
  let forms = yield call(API.fetchForms);
  if (isTeamResourcePicker()) {
    const teamForms = yield call(API.getTeamResources, { teamID: TEAM_ID, resourceType: 'form' });
    forms = [...forms, ...teamForms];
  }
  yield put({ type: ACTION_TYPES.FETCH_FORMS.SUCCESS, payload: forms });
}

function* fetchTeamResources(resourceType, actionType) {
  const teamID = getTeamID();
  const response = yield call(API.getTeamResources, { teamID, resourceType });
  yield put({ type: actionType, payload: response });
}

export function* watchFetchResources(resourceType, actionType, regularAction) {
  const actions = {
    team: () => call(fetchTeamResources, resourceType, actionType),
    regular: () => call(regularAction)
  };

  yield actions[isTeamResourcePicker() ? 'team' : 'regular']();
}

const prepateSignDocuments = signs => {
  const preparedSigns = signs.map(sign => {
    const { signerCount, embedDocumentKey, formID } = sign;

    const handleGoToSign = () => {
      handleCustomNavigation(`/sign/${formID}/send/embed`, '_blank');
    };

    const errorText = (
      <>
        Only single signer and
        {' '}
        <span className="link" onClick={handleGoToSign}>link generated</span>
        {' '}
        documents can be added
      </>
    );

    if (signerCount < 1) {
      return {
        ...sign, errorText, disabled: true, noSigner: true
      };
    }

    if (signerCount > 1) {
      return {
        ...sign, errorText, disabled: true, multipleSigner: true
      };
    }

    if (!embedDocumentKey) {
      return {
        ...sign, errorText, disabled: true, noLink: true
      };
    }

    return sign;
  });

  const sortSigns = items => {
    return items.sort((a, b) => {
      const date1 = new Date(a.modificationDate);
      const date2 = new Date(b.modificationDate);
      return date1.getTime() - date2.getTime();
    });
  };

  const noLinkSigns = preparedSigns.filter(item => item.noLink);
  const noSignerSigns = preparedSigns.filter(item => item.noSigner);
  const multipleSigners = preparedSigns.filter(item => item.multipleSigner);
  const demonstrableSings = preparedSigns.filter(item => !item.disabled);
  const sortedSigns = sortSigns(demonstrableSings);

  // change the parts if it needed..
  return [...sortSigns(sortedSigns), ...sortSigns([...noLinkSigns, ...noSignerSigns, ...multipleSigners])];
};

export function* wathcFetchSigns() {
  if (!isFeatureEnabled(FEATURE_NAMES.SignField)) {
    return;
  }
  const appID = yield select(SELECTORS.getAppID);
  const signs = yield call(API.fetchSigns, appID);
  const preparedSigns = prepateSignDocuments(signs);
  yield put({ type: ACTION_TYPES.FETCH_SIGNS.SUCCESS, payload: preparedSigns });
}

export function* portalCreationError(error) {
  const { data: { responseCode } = {} } = error;
  let reason = 'default';
  switch (responseCode) {
    case 429:
      const isUserLoggedIn = yield select(SELECTORS.selectIsUserLoggedIn);
      reason = isUserLoggedIn ? PORTAL_ERROR_MAP.CREATE.CREATE_APP_RATE_LIMIT : PORTAL_ERROR_MAP.CREATE.CREATE_APP_RATE_LIMIT_GUEST;
      break;
    case 500:
      reason = PORTAL_ERROR_MAP.CREATE.CREATE_APP;
      break;
    default:
  }
  yield put({ type: ACTION_TYPES.SET_APP_STATUS, payload: { error } });
  yield put({ type: ACTION_TYPES.CREATE_NEW_PORTAL.ERROR, payload: PORTAL_ERROR_MAP.CREATE[reason], error });
}

export function* watchCreatePortal({ formIDs = null, widgetDefaults = null, createWithFormProperties = {} }) {
  try {
    const appPropertiesDefault = useAppDefaults();
    const appProperties = { ...appPropertiesDefault, ...createWithFormProperties };
    const homePage = { id: 0, pageOrder: 0 };
    const _page = isFeatureEnabled(FEATURE_NAMES.SortPages) ? homePage : null;
    const app = yield call(API.createPortal, formIDs, appProperties, widgetDefaults, _page);
    const { id } = app;
    const isFromStartFromScratch = StorageHelper.getSessionStorageItem({ key: 'create_app_from_scratch' });
    const from = isFromStartFromScratch && { from: 'wizard:start-from-scratch' };

    yield put(ACTION_CREATORS.trackEventAction({ action: 'appCreated', target: { portalID: id, ...from } }));
    StorageHelper.removeSessionStorageItem({ key: 'create_app_from_scratch' });
    yield call(normalizeAndRegisterApp, app);
    yield put({ type: ACTION_TYPES.CREATE_NEW_PORTAL.SUCCESS, payload: { formIDs, widgetDefaults } });
    navigate(`${getAppPath()}/build/${id}${window.location.search}`, { replace: true });
  } catch (error) {
    yield call(portalCreationError, error);
  }
}

export function* watchBuildOrCreate({ payload: formID }) {
  yield put(ACTION_CREATORS.fetchFormsAction());
  const portalsOfForm = yield call(API.fetchPortalsByFormID, formID);
  if (portalsOfForm.length === 0) {
    navigate(`${getAppPath()}/create/${formID}`, { replace: true });
    return;
  }
  yield put({ type: ACTION_TYPES.FETCH_APPS.SUCCESS, payload: portalsOfForm });
}

export function* shareFlow() {
  yield takeEvery(ACTION_TYPES.BULK_SHARE_PORTAL.REQUEST, watchSharePortal);
  yield takeEvery(ACTION_TYPES.BULK_DELETE_SHARE_PORTAL.REQUEST, watchShareDeletePortal);
  yield takeEvery(ACTION_TYPES.UPDATE_RESOURCE_SHARE_URL.REQUEST, watchResourceShareURLUpdate);
  yield takeLatest(ACTION_TYPES.FETCH_SHARE_LIST.REQUEST, watchFetchShareList);
}

export function* watchItemOrderUpdate(action) {
  const { payload, dontStack = false } = action;
  const {
    data, sortInfo, page, elementID
  } = payload;

  const pageHeadingItems = data.filter(i => i.page === page).filter(i => i.type === ITEM_TYPES.HEADING);
  const isItFirstHeading = pageHeadingItems.length === 1;

  if (isItFirstHeading) {
    const { title } = yield select(SELECTORS.getPortalItemByIDSelector(elementID));
    yield put({
      type: ACTION_TYPES.WATCH_HEADING_ITEM_FOR_PAGE_NAMING,
      payload: {
        itemID: elementID,
        prop: {
          title,
          newPage: page
        }
      }
    });
  }

  const requestAction = { type: ACTION_TYPES.UPDATE_ORDER.REQUEST, payload: data };
  const portalID = yield select(SELECTORS.getPortalIDSelector);
  const currentData = yield select(SELECTORS.getPortalItems);
  yield put(requestAction);

  const result = yield call(API.bulkUpdateItems, portalID, data);

  yield put({
    type: ACTION_TYPES.UPDATE_ORDER.SUCCESS,
    payload: result,
    currentData,
    requestAction: action,
    dontStack
  });

  yield put(ACTION_CREATORS.trackEventAction({ action: 'itemOrdersUpdated', target: sortInfo }));
}

function* checkLoginableApp() {
  const { loginable, id } = yield select(SELECTORS.getAppInfoWithDefaults);
  if (isYes(loginable)) {
    return;
  }
  // make portal loginable
  yield put(ACTION_CREATORS.updatePortalAction({ loginable: 'Yes' }));
  const redirectPublish = () => {
    navigate(`${getAppPath()}/build/${id}/publish/link/shareSettings`);
  };
  yield put(ACTION_CREATORS.toastAction({
    message: 'Users now can access their submissions through the app.',
    buttonText: 'Go to Publish',
    onButtonClick: redirectPublish
  }));
}

// TODO :: seperate this
/* eslint-disable max-statements */
/* eslint-disable-next-line complexity */
export function* watchItemPropUpdate(action) {
  const {
    payload: {
      itemID, prop, linkedItemID = null, bypassDebounce = false
    }
  } = action;
  const portalID = yield select(SELECTORS.getPortalIDSelector);
  if (!itemID) {
    throw new Error('No item ID specified');
  }

  let updatingProps = { ...prop };
  let linkPreviewResolved = false;
  const itemProps = yield select(SELECTORS.getPortalItemByIDSelector(itemID));
  const {
    showLinkPreview: currentShowLinkPreview, type, title: itemTitle, formTitle
  } = itemProps;
  const updatingShowLinkPreview = updatingProps?.showLinkPreview;

  if (type === ITEM_TYPES.LINK) {
    const updatingLinkWithPreviewEnabled = Object.keys(prop).includes('title') && isYes(currentShowLinkPreview);
    const turningPreviewOn = isYes(updatingShowLinkPreview);
    // Do not send request when showLinkPreview is No.
    if (updatingLinkWithPreviewEnabled || turningPreviewOn) {
      const sanitizedTitle = (prop.title || '').replace(/^https:\/\/(https?):\/\//, '$1://'); // replace w/ first matched group // TODO try to handle other protocols and occurrences too
      const linkTargetIsMailTo = sanitizedTitle.startsWith('mailto:'); // Dirty fix, validUrl check gets confused about mailto..
      const URL = checkAndAddProtocolToTargetLink(sanitizedTitle || itemTitle);
      if (isValidLinkTarget(URL) && !linkTargetIsMailTo) {
        yield put(ACTION_CREATORS.updateItemLoadingStatus({ [itemID]: true }));
        linkPreviewResolved = true;
        // get preview
        const {
          title: previewTitle,
          image: previewImageURL,
          imageWidth: previewImageWidth,
          imageHeight: previewImageHeight
        } = yield call(API.getLinkItemPreviewDetails, { portalID, itemID, URL });

        // Enrich props
        updatingProps = {
          ...updatingProps,
          previewTitle,
          previewImageURL,
          previewImageWidth,
          previewImageHeight
        };

        if (prop.title && sanitizedTitle !== prop.title) {
          updatingProps = {
            ...updatingProps,
            title: sanitizedTitle
          };
        }

        // Track
        yield put(ACTION_CREATORS.trackEventAction({ action: 'linkPreviewResolved', target: { itemID } }));
      } else {
        updatingProps = {
          ...updatingProps,
          previewTitle: 'Link',
          previewImageURL: '',
          previewImageWidth: '',
          previewImageHeight: ''
        };
      }
    }
  }

  // Guard for empty form titles
  if ([ITEM_TYPES.FORM, ITEM_TYPES.SIGN_LINK].includes(type)) {
    const { title, required_showBadge, completed_showBadge } = updatingProps;
    if (title === '' && type === ITEM_TYPES.FORM) {
      updatingProps = { ...updatingProps, title: formTitle };
    }
    // todo :: handle elsewhere
    if (isYes(required_showBadge)) updatingProps = { ...updatingProps, completed_showBadge: required_showBadge };

    if ((isYes(required_showBadge) || isYes(completed_showBadge))) {
      yield call(checkLoginableApp);
    }
  }

  if (ITEM_TYPES.FORM === type && !!prop.embeddedForm && itemProps.isInitialForm === 'Yes') {
    const _action = prop.embeddedForm === 'Yes' ? 'enabled' : 'disabled';
    logAbTestActionFor(AB_TESTS.ShowFormFromFormBuilder, { action: _action, target: 'showForm' });
  }

  const currentItem = yield select(SELECTORS.getItemWithDefaults(itemID));

  const { buttonRole, buttonValue: updatingButtonValue } = updatingProps;

  if (ActionHelper.ACTIONABLE_ITEMS.includes(type)) {
    const ItemActionHelper = ActionHelper.createComponent(type);
    const userInfo = yield select(SELECTORS.getUser);
    const firstPageID = yield select(SELECTORS.getFirstPageID);
    const pages = yield select(SELECTORS.getPortalPages);
    const pagesLength = pages?.length;

    const { title, buttonRole: currentButtonRole, buttonValue } = currentItem;
    const { title: currentFormTitle } = yield select(SELECTORS.getFormByID(buttonValue));

    const isTitleFormTitle = title === currentFormTitle;
    const isTitleDefault = ItemActionHelper.isTitleDefault(title);

    if (!updatingButtonValue && buttonRole) {
      const { defaultTexts } = ItemActionHelper;
      const updatingButtonProps = updateAndOverrideButtonProperties({
        defaultTexts,
        currentItem,
        updatingProps,
        userInfo,
        isTitleFormTitle,
        isTitleDefault,
        navigationDefaultPage: pagesLength > 1 ? firstPageID : ''
      });
      updatingProps = { ...updatingProps, ...updatingButtonProps };
    }

    if (currentButtonRole === BUTTON_ROLE_TYPES.FORM && updatingButtonValue) {
      const { title: updatingFormTitle } = yield select(SELECTORS.getFormByID(updatingButtonValue));

      const newTitle = (!title || isTitleDefault || isTitleFormTitle) ? { title: updatingFormTitle } : {};

      // We need to send buttonRole even if it's not one of the changing props
      updatingProps = { ...updatingProps, ...newTitle, buttonRole: currentButtonRole };
    }
  }

  if (type === ITEM_TYPES.HEADING) {
    yield put({ type: ACTION_TYPES.WATCH_HEADING_ITEM_FOR_PAGE_NAMING, payload: action.payload });
  }

  // Make icon svg urls relative
  if (updatingProps.itemIcon) {
    updatingProps.itemIcon = sanitizeSVGIconURLs(updatingProps.itemIcon);
  }

  const currentData = Object.keys(updatingProps).reduce((pre, next) => {
    return { ...pre, [next]: currentItem[next] };
  }, {});

  const shouldUpdate = shouldUpdatePropFromResponse(type, updatingProps);

  const actionType = bypassDebounce ? ACTION_TYPES.UPDATE_ITEM_PROP.WITHOUT_DEBOUNCE : ACTION_TYPES.UPDATE_ITEM_PROP.WITH_DEBOUNCE;

  yield put({
    type: actionType,
    payload: {
      itemID,
      prop: updatingProps,
      linkedItemID
    },
    shouldUpdate,
    currentData,
    linkPreviewResolved,
    requestAction: action,
    currentItem
  });
}

export function* watchItemPropUpdateRequest(action) {
  const isBuilder = yield select(SELECTORS.getIsBuilder);
  if (!isBuilder) return;

  const portalID = yield select(SELECTORS.getPortalIDSelector);
  const previewPanelOpened = yield select(SELECTORS.isPreviewPanelOpenSelector);

  const {
    payload: { itemID, prop: updatingProps, linkedItemID = null }, linkPreviewResolved, currentData, shouldUpdate, currentItem
  } = action;

  try {
    let result = yield call(API.changeItemProp, portalID, itemID, updatingProps);

    if (shouldUpdate) {
      const mutaterFunc = resultMutaters(currentItem)?.[currentItem?.type];
      if (mutaterFunc && typeof mutaterFunc === 'function') {
        result = mutaterFunc(result);
      }
    }

    if (shouldUpdate || previewPanelOpened) {
      yield put({
        type: ACTION_TYPES.UPDATE_ITEM_PROP.SUCCESS,
        payload: result,
        requestAction: action,
        currentData
      });
    }

    if (currentItem?.page) {
      const pageProps = yield select(SELECTORS.getPageByID(currentItem.page));
      if (pageProps?.linkedItemID) {
        const activeRowID = yield select(SELECTORS.dsGetActiveListRowID(pageProps.linkedItemID));
        yield put(ACTION_CREATORS.dsFetchRow({ itemID, rowID: activeRowID }));
      } else if (linkedItemID) {
        yield put(ACTION_CREATORS.setLivePreviewStatus(APP_PREVIEW_STATES.LOADING));

        const isButtonRoleUpdate = Object.keys(updatingProps).includes('buttonRole');
        yield put(ACTION_CREATORS.dsFetchSourceItemData({ itemID: linkedItemID, isButtonRoleUpdate }));
      }
    }

    if (currentItem?.type === ITEM_TYPES.LIST && Object.keys(updatingProps)?.includes('resourceID')) {
      yield put(ACTION_CREATORS.dsFetchColumnsRequest({ resourceID: updatingProps.resourceID, viewID: updatingProps?.viewID }));
      yield put(ACTION_CREATORS.dsFetchSourceItemData({ itemID: currentItem.id }));
      yield put(ACTION_CREATORS.dsSetActiveRowID({ itemID, rowID: null }));
    }

    if (linkPreviewResolved) { // TODO Handle error cases too
      yield put(ACTION_CREATORS.updateItemLoadingStatus({ [itemID]: false }));
    }

    // Track
    const resultsEntries = Object.entries(result);
    for (let i = 0; i < resultsEntries.length; i++) {
      const [updatedItemID, value] = resultsEntries[i];
      const updatedPropKeys = Object.keys(value);
      yield put(ACTION_CREATORS.trackEventAction({ action: 'itemUpdated', target: { itemID: updatedItemID, prop: updatedPropKeys } }));
    }
    if (linkedItemID) {
      yield put(ACTION_CREATORS.setAppUpdatedAt(true));
    }
  } catch (err) {
    console.error(err);
    yield put(ACTION_CREATORS.toastAction({
      message: 'Changes are not saved',
      error: true,
      type: 'error'
    }));
  }
}
/* eslint-enable max-statements */

export function* watchCreateFormFromTemplate({ payload: { cloneData } }) {
  const { status, data: { form: { id: formID } } } = yield call(API.cloneTemplate, 'form', cloneData);
  yield put(ACTION_CREATORS.trackEventAction({ action: 'templateUsed', target: { templateID: cloneData.formID } }));
  if (status !== 200) {
    yield put({ type: ACTION_TYPES.CREATE_FORM_FROM_TEMPLATE.ERROR });
    return;
  }

  yield put(ACTION_CREATORS.fetchFormsAction());
  const formProps = {
    id: formID,
    ...useItemDefaults(ITEM_TYPES.FORM)
  };
  yield put(ACTION_CREATORS.addPortalItemAction(formProps));
}

export function* watchCreateFormFromScratch({ payload: formID }) {
  yield put(ACTION_CREATORS.trackEventAction({ action: 'formCreated', target: { formID: formID } }));
  if (!formID) {
    yield put({ type: ACTION_TYPES.CREATE_FORM_FROM_SCRATCH.ERROR });
    return;
  }

  yield put(ACTION_CREATORS.fetchFormsAction());
  const formProps = {
    id: formID,
    ...useItemDefaults(ITEM_TYPES.FORM)
  };
  yield put(ACTION_CREATORS.addPortalItemAction(formProps));
}

export function* watchCreateSignDocumentFromTemplate({ payload: { cloneData, confirmData } }) {
  const { status, data: { formID, documentID, embedDocumentKey = '' } } = yield call(API.cloneTemplate, 'pdf', cloneData);
  yield put(ACTION_CREATORS.trackEventAction({ action: 'signTemplateUsed', target: { templateID: cloneData.id } }));
  if (status !== 200) {
    yield put({ type: ACTION_TYPES.CREATE_SIGN_DOCUMENT_FROM_TEMPLATE.ERROR });
    return;
  }
  const signElementProps = {
    formID, id: documentID, embedDocumentKey, ...useItemDefaults(ITEM_TYPES.SIGN_LINK)
  };
  yield put(ACTION_CREATORS.onResourcePickerModalConfirm({ ...confirmData, selectedResourcesArray: [signElementProps] }));
}

export function* watchMultipleItemUpdate(action) {
  const { payload, dontStack = false } = action;
  const portalID = yield select(SELECTORS.getPortalIDSelector);
  const currentPortalItems = yield select(SELECTORS.getPortalItems);
  const itemsToUpdate = {};
  let checkAppIsLoginable = false;
  payload.forEach(({ id: itemID, ...itemProps }, key) => {
    let updatedProps = itemProps;
    // todo :: handle elsewhere
    const showRequiredBadge = isYes(itemProps.required_showBadge);
    if (showRequiredBadge) {
      updatedProps = { ...itemProps, completed_showBadge: itemProps.required_showBadge };
      payload[key].completed_showBadge = itemProps.required_showBadge;
    }
    // We must prevent enabling shrink from multiple right panel if embeddedForm is enabled
    if (isYes(updatedProps.shrink) && isYes(!updatedProps.embeddedForm)) {
      const { embeddedForm } = currentPortalItems.find(({ id }) => id === itemID);
      if (isYes(embeddedForm)) {
        updatedProps = { ...updatedProps, shrink: 'No' };
        payload[key].shrink = 'No';
      }
    }
    if ((showRequiredBadge || isYes(itemProps.completed_showBadge))) {
      checkAppIsLoginable = true;
    }
    itemsToUpdate[itemID] = updatedProps;
  });

  if (checkAppIsLoginable) {
    yield call(checkLoginableApp);
  }
  const updatedItemIDs = Object.keys(itemsToUpdate);
  if (updatedItemIDs.length === 0) {
    return;
  }
  const currentData = currentPortalItems.filter(({ id }) => updatedItemIDs.includes(id));
  yield put({
    type: ACTION_TYPES.UPDATE_MULTIPLE_ITEM.REQUEST,
    payload: itemsToUpdate,
    currentData,
    requestAction: action
  });

  const response = yield call(API.bulkUpdateItems, portalID, payload);

  yield put({
    type: ACTION_TYPES.UPDATE_MULTIPLE_ITEM.SUCCESS,
    payload: response,
    currentData,
    requestAction: action,
    dontStack
  });
  yield put(ACTION_CREATORS.trackEventAction({ action: 'bulkItemUpdate', target: { itemIDs: Object.keys(response) } }));
}

export function* updateSlug({
  slug,
  forReset,
  reject: rejectCheck,
  resolve: resolveCheck
}) {
  try {
    const prevSlug = yield select(SELECTORS.getAppSlug);
    if (prevSlug === slug) {
      resolveCheck();
      return;
    }
    if (!forReset) {
      const { isSlugAvailable } = yield call(API.isSlugAvailable, slug);
      if (!isSlugAvailable) {
        rejectCheck(t('Slug is already in use.'));
        return;
      }
    }

    yield put({
      type: ACTION_TYPES.UPDATE_PORTAL.UNDOABLE,
      payload: {
        slug
      }
    });
    resolveCheck();
  } catch (err) {
    if (err?.data?.message) {
      const errorMessageFromAPI = ERROR_MESSAGES[err.data?.message] || ERROR_MESSAGES.DEFAULT;
      rejectCheck(t(errorMessageFromAPI));
    } else {
      rejectCheck(t('Slug is already in use.'));
    }
  }
}

export function* watchUserSettingUpdate({ payload: userData }) {
  const username = yield select(SELECTORS.getUsername);
  if (username) {
    const res = yield call(API.updateUserSettings, username, userData);
    const updatedKeys = Object.keys(userData);
    // use only updated keys
    const payload = updatedKeys.reduce((prev, next) => ({ ...prev, [next]: res[next] }), {});
    yield put({ type: ACTION_TYPES.UPDATE_USER.SUCCESS, payload });
  }
}

export function* watchContinueAsUser() {
  const appID = yield select(SELECTORS.getAppID);
  yield call(API.portalUserAddCurrentUser, appID);
  yield put({ type: ACTION_TYPES.CONTINUE_AS_USER.SUCCESS, payload: false });
}

export function* watchAPIRequests() {
  const currentUpdateReqActions = yield actionChannel(({ type }) => isUpdateAPIAction(type), buffers.expanding());
  // check if there are upcoming updates
  const savingProcess = yield select(SELECTORS.areAPIRequestsCompletedSelector);
  try {
    while (true) {
      const { type: actionType } = yield take(currentUpdateReqActions);
      if (isAPIRequestAction({ type: actionType }) && !savingProcess) {
        yield put(ACTION_CREATORS.setApiRequestsCompleted(false));
      } else if (isAPISuccessAction({ type: actionType })) {
        yield put(ACTION_CREATORS.setApiRequestsCompleted(true));
      }
    }
  } catch (e) {
    //
  }
}

function* sendFeedback({ payload }) {
  const {
    stars, feedbackText, feedbackEmail, formID: feedbackFormID
  } = payload;
  const portalID = yield select(SELECTORS.getPortalIDSelector);
  const currentUser = yield select(SELECTORS.getUser);
  const { username, email } = currentUser;
  const emailValue = feedbackEmail || email;
  const feedbackData = {
    ...stars && {
      q5_howWould: stars,
      q5_stars: stars,
      q7_greatWhat7: (stars > 3 ? feedbackText : ''),
      q9_whatCould: (stars === 3 ? feedbackText : ''),
      q8_wereSorry: (stars < 3 ? feedbackText : '')
    },
    ...!stars && {
      q9_whatCould: feedbackText || ''
    },
    q11_appid: portalID,
    q3_username: username,
    q20_email: emailValue,
    // plase don't change below 3 keys & values
    formID: feedbackFormID,
    q18_ticketcategoryid: 37,
    q19_assignee: 'Orion_Team'
  };

  try {
    yield call(API.sendFeedbackSubmission, feedbackData);
    yield put({ type: ACTION_TYPES.SEND_FEEDBACK.SUCCESS });
  } catch (e) {
    yield put({ type: ACTION_TYPES.SEND_FEEDBACK.ERROR, error: e });
  }
}

function* fetchApps() {
  const { id: appID } = yield select(SELECTORS.getAppInfoWithDefaults);
  const apps = yield call(API.fetchUserPortals, appID);
  yield put({ type: ACTION_TYPES.FETCH_APPS.SUCCESS, payload: apps });
}

export function* watchFetchUserApps() {
  yield call(watchFetchResources, 'portal', ACTION_TYPES.FETCH_APPS.SUCCESS, fetchApps);
}

export function* watchDeleteSelectedItems() {
  const multipleSelecteds = yield select(SELECTORS.getSelectedMultipleItems);
  const items = yield select(SELECTORS.getPortalItems);
  const listItemsToRemoved = items.filter(({ type, id }) => type === ITEM_TYPES.LIST && multipleSelecteds.includes(id));
  yield all(listItemsToRemoved?.map(item => put(ACTION_CREATORS.dsDeleteDetailPage({ item }))));
  const presentationItemIDs = listItemsToRemoved.map(({ presentationItemID }) => presentationItemID);

  yield put(ACTION_CREATORS.removePortalItems([...multipleSelecteds, ...presentationItemIDs]));
}

export function* watchBrandingButtonClick() {
  const appMode = yield select(SELECTORS.getAppModeSelector);
  const isBuilder = appMode === APP_MODES.builder;
  const appID = yield select(SELECTORS.getPortalIDSelector);
  const utmScheme = {
    ...BRANDING_DEFAULT_UTM_SCHEME,
    ...(isBuilder && { source: 'appBuilder' }),
    appID
  };
  const utmString = utmParser(utmScheme);
  if (!isBuilder) yield put(ACTION_CREATORS.trackEventAction({ action: 'brandingFooterClicked' }));
  handleCustomNavigation(`${isBuilder ? '/pricing' : 'https://www.jotform.com/myapps/'}?${utmString}&action=createWizard`, '_blank', true);
}

function* templateCategoriesFlow() {
  const appMode = yield select(SELECTORS.getAppModeSelector);
  if (appMode === APP_MODES.builder && isFeatureEnabled(FEATURE_NAMES.ShareAsTemplate)) {
    const response = yield call(API.getTemplateCategories);
    yield put({ type: ACTION_TYPES.SET_TEMPLATE_CATEGORIES, payload: response });

    const { data: { languages } } = yield call(API.getTemplateLanguages);
    yield put({ type: ACTION_TYPES.SET_TEMPLATE_LANGUAGES, payload: languages });
  }
}

function* svgIconsFlow() {
  const appMode = yield select(SELECTORS.getAppModeSelector);
  if (appMode === APP_MODES.builder) {
    // put icons svgRefs to the dom
    yield call(prepareIcons, appConfig.svgIconsContainer);
  }
}

export function* fetchCDNConfigFlow() {
  const appMode = yield select(SELECTORS.getAppModeSelector);
  if (appMode === APP_MODES.builder || isEnterprise()) {
    yield put({ type: ACTION_TYPES.FETCH_CDN_CONFIG.REQUEST });
    const result = yield call(API.fetchCDNConfig);
    const { configs = {} } = result;
    const CDN = (!configs.cloudURL || (configs.cloudURL && configs.cloudURL.length === 0)) ? '/' : configs.cloudURL;
    const config = { ...configs, CDN };
    yield put({ type: ACTION_TYPES.FETCH_CDN_CONFIG.SUCCESS, payload: config });
  }
}

export function* fetchResources() {
  const appMode = yield select(SELECTORS.getAppModeSelector);
  if (appMode === APP_MODES.builder) {
    yield put(ACTION_CREATORS.fetchFormsAction());
    yield put(ACTION_CREATORS.fetchSignAction());
  }
}

function* fetchUserApps() {
  yield take(ACTION_TYPES.FETCH_PORTAL.SUCCESS);
  const appMode = yield select(SELECTORS.getAppModeSelector);
  if (appMode === APP_MODES.builder) {
    yield put(ACTION_CREATORS.fetchUserAppsAction());
  }
}

export function* watchLayoutChange(action) {
  const { payload: appLayout } = action;
  const { portalProps } = getLayoutProps(appLayout);

  if (portalProps) {
    yield put(ACTION_CREATORS.updatePortalAction(portalProps));
  }

  const items = yield select(SELECTORS.getPortalItems);

  const updatingItems = items.reduce((prev, item) => {
    const { type } = item;
    const { itemProps } = getLayoutProps(appLayout, type);
    const stylingProps = getProtectedStylingProps(appLayout, item);

    return [...prev, { ...item, ...itemProps, ...stylingProps }];
  }, []);

  yield put(ACTION_CREATORS.updateMultipleItemAction(updatingItems));
}

function* fetchUserSlugFlow() {
  yield all([take(ACTION_TYPES.FETCH_USER.SUCCESS), take(ACTION_TYPES.FETCH_PORTAL.SUCCESS)]);

  try {
    const [appMode, appInfo] = yield all([
      select(SELECTORS.getAppModeSelector),
      select(SELECTORS.getPortalInfoSelector)
    ]);

    if (appMode === APP_MODES.builder && appInfo?.username) {
      const { slug } = yield call(API.getUserSlug, appInfo.username);
      yield put({ type: ACTION_TYPES.SET_USER_SLUG, slug });
    }
  } catch (e) {
    console.error('Error on fetch user slug');
  }
}

function* watchUserChange(action) {
  const { payload: user } = action;

  const { id: appID } = yield select(SELECTORS.getAppInfoWithDefaults);

  yield call(API.claimGuestAccount, appID);

  try {
    yield call(API.portalUserAddCurrentUser, appID);
  } catch (err) {
    console.error('Error on adding portal user: ', err);
  }

  yield put(ACTION_CREATORS.setUserAction(user));

  yield put(ACTION_CREATORS.fetchPortalAction(appID));
}

export function* watchUpdatePortalUserProps({ payload }) {
  const portalID = yield select(SELECTORS.getPortalIDSelector);

  const data = yield call(API.updatePortalUserProperty, portalID, payload);
  yield put({ type: ACTION_TYPES.UPDATE_PORTAL.REQUEST, payload: data });
}

export function* watchCheckoutFormStatusChanges({ payload }) {
  switch (payload) {
    case CHECKOUT_FORM_STATUSES.COMPLETED:
      const isDonationApp = yield select(SELECTORS.getIsDonationApp);
      if (isDonationApp) {
        const portalPaymentCurrencyInfo = yield select(SELECTORS.getPortalPaymentCurrencyInfo);
        const donationPrice = yield select(SELECTORS.getDonationPriceInCart);
        const oldDonationGoal = yield select(SELECTORS.getCurrentDonationGoal);
        const currentDonationGoal = sumMoney({ ...portalPaymentCurrencyInfo, price: oldDonationGoal }, donationPrice).toString();
        yield put(ACTION_CREATORS.updatePortalPropAction({ currentDonationGoal, lastOrderAmount: donationPrice }));
      }
      yield put({ type: ACTION_TYPES.SET_CART_PRODUCTS, payload: {} });
      yield put({ type: ACTION_TYPES.SET_CHECKOUT_KEY, payload: '' });
      break;
    default:
      break;
  }
}

function* watchNavigationItemClick({ payload: { targetPageID } }) {
  yield put(ACTION_CREATORS.navigateToAction({ to: DESTINATION_TYPES.PAGE, pageID: targetPageID }));
}

function* watchSetTodoComplete({ payload: { formID, submissionCount } }) {
  const lastSubmitted = momentToString(Moment(getUpdatedDate(new Date().getTime(), 'America/New_York', true)), true);
  yield put(ACTION_CREATORS.setTodoItemCompleted({
    itemID: formID,
    prop: { lastSubmitted, submissionCount: submissionCount + 1 }
  }));
}

function* createNewPortalWithAny(onAppCreated, customAppProperties = {}, from) {
  try {
    const isMobilePhone = checkMobilePhone();
    const appPropertiesDefault = useAppDefaults();
    const appProperties = { ...appPropertiesDefault, ...customAppProperties };
    const homePage = { id: 0, pageOrder: 0 };
    const _page = isFeatureEnabled(FEATURE_NAMES.SortPages) ? homePage : null;
    const app = yield call(API.createPortal, null, appProperties, null, _page);
    const { id } = app;
    yield put(ACTION_CREATORS.trackEventAction({ action: 'appCreated', target: { portalID: id, ...from } }));
    yield call(normalizeAndRegisterApp, app);
    const isDonationActive = isFeatureEnabled(FEATURE_NAMES.DonationItem);
    let redirectUrl = `${getAppPath()}/build/${id}`;
    if (isDonationActive) {
      redirectUrl += '?useNewBuilder';
    }
    yield call(navigate, redirectUrl, { replace: true });
    if (id) {
      yield call(onAppCreated);
      if (!isMobilePhone) {
        yield put(ACTION_CREATORS.openRightPanelWithModeAction(RightPanelModes.APP_ITEM));
      }
    }
  } catch (error) {
    yield call(portalCreationError, error);
  }
}

export function* createNewPortalWithTemplate({ payload }) {
  try {
    const app = yield call(API.fetchPortal, payload.appID);
    yield call(normalizeAndRegisterApp, app);
    const redirectUrl = `${getAppPath()}/build/${payload.appID}`;
    yield call(navigate, redirectUrl, { replace: true });
    StorageHelper.removeSessionStorageItem({ key: 'create_app_from_app_templates' });
  } catch (error) {
    yield call(portalCreationError, error);
  }
}

export function* createNewPortalWithStore() {
  const isFromStoreBuilder = StorageHelper.getSessionStorageItem({ key: 'create_app_from_store_builder' });
  const from = isFromStoreBuilder && { from: 'create_app_from_store_builder' };
  function* onAppCreated() {
    const itemProps = {
      type: 'PRODUCT_LIST',
      title: t('Products'),
      page: '0'
    };
    yield put(ACTION_CREATORS.addPortalItemAction(itemProps, -1, true));
    yield take(ACTION_TYPES.ADD_PORTAL_ITEMS.SUCCESS);
    yield put(ACTION_CREATORS.selectPortalItemAction('1'));
  }
  yield call(createNewPortalWithAny, onAppCreated, {}, from);
  StorageHelper.removeSessionStorageItem({ key: 'create_app_from_store_builder' });
}

export function* createNewPortalWithDonation() {
  function* onAppCreated() {
    const getItemDefaults = yield select(SELECTORS.getItemDefaultsGetter);
    yield put(ACTION_CREATORS.addPortalItemAction([
      { ...getItemDefaults(ITEM_TYPES.IMAGE), page: '0' },
      { ...getItemDefaults(ITEM_TYPES.DONATION), page: '0' }
    ]));
    yield put(ACTION_CREATORS.trackEventAction({ action: 'donationBoxAddedFromURL', target: { url: '/createWithDonationBuilder' } }));
    yield take(ACTION_TYPES.ADD_PORTAL_ITEMS.SUCCESS);
    yield put(ACTION_CREATORS.selectPortalItemAction('1'));
  }
  let customAppProperties = {
    [APP_HEADER_PROPS.appHeaderBgColor]: '#B2E0FF'
  };

  if (global.JOTFORM_ENV !== 'ENTERPRISE') {
    customAppProperties = {
      ...customAppProperties,
      appIconColor: '#0099FF',
      appIconBackground: '#FFFFFF',
      appIconSvgRef: 'jfc_icon_solid-heart',
      appIconURL: '/cardforms/assets/icons/icon-sets-v2/solid/Basic UI/jfc_icon_solid-heart.svg',
      appIconType: IMAGE_TYPE.icon,

      logoBackground: '#FFFFFF',
      logoIconSvgRef: 'jfc_icon_solid-heart',
      logoURL: '/cardforms/assets/icons/icon-sets-v2/solid/Basic UI/jfc_icon_solid-heart.svg',
      logoType: IMAGE_TYPE.icon,
      iconColor: '#0099FF'
    };
  }

  yield call(createNewPortalWithAny, onAppCreated, customAppProperties);
}
export function* watchIsAppDone({ payload: isAppDone }) {
  const shouldShowProgressBar = yield select(SELECTORS.getShouldShowProgressBar);
  if (isAppDone && shouldShowProgressBar) {
    yield put(ACTION_CREATORS.showGenericModalAction({ name: MODALS.APP_IS_DONE_MODAL }));
  }
}

export function* watchFormPickerModalConfirm({ payload }) {
  const {
    selection, portalOrder = ITEM_ADDITION_ORDER_STRATEGY.TO_END_OF_THE_PAGE, page, withClicking = false
  } = payload;
  const lastSelectedItemOrder = yield select(SELECTORS.getLastSelectedItemOrder);
  const getItemDefaults = yield select(SELECTORS.getItemDefaultsGetter);
  const formItems = selection.map(formID => ({ id: formID, type: ITEM_TYPES.FORM }));
  const useDndDropOrder = portalOrder && portalOrder !== ITEM_ADDITION_ORDER_STRATEGY.TO_END_OF_THE_PAGE;
  const newPortalOrder = useDndDropOrder ? portalOrder : lastSelectedItemOrder;

  const formsWithPage = formItems.reduce((prev, item) => ([...prev, { ...item, page }]), []);

  const forms = (page && page !== 'homepage') ? formsWithPage : formItems;
  const formsWithDefaultValues = forms.map(form => {
    return {
      ...form,
      ...getItemDefaults(ITEM_TYPES.FORM)
    };
  });
  yield put(ACTION_CREATORS.addPortalItemAction(formsWithDefaultValues, newPortalOrder, withClicking));
}

export function* watchResourcePickerModalConfirm({ payload }) {
  const {
    selectedResourcesArray,
    resourceType,
    addOrder = ITEM_ADDITION_ORDER_STRATEGY.TO_END_OF_THE_PAGE,
    page,
    withClicking = false
  } = payload;
  const lastSelectedItemOrder = yield select(SELECTORS.getLastSelectedItemOrder);
  const getItemDefaults = yield select(SELECTORS.getItemDefaultsGetter);
  const appID = yield select(SELECTORS.getAppID);
  const useDndDropOrder = addOrder && addOrder !== ITEM_ADDITION_ORDER_STRATEGY.TO_END_OF_THE_PAGE;
  const newPortalOrder = useDndDropOrder ? addOrder : lastSelectedItemOrder;

  const itemTypesMap = {
    [RESOURCE_TYPES.TABLE]: ITEM_TYPES.TABLE_LINK,
    [RESOURCE_TYPES.REPORT]: ITEM_TYPES.REPORT_LINK,
    [RESOURCE_TYPES.SENTBOX]: ITEM_TYPES.SENTBOX_LINK,
    [RESOURCE_TYPES.SIGN]: ITEM_TYPES.SIGN_LINK,
    [RESOURCE_TYPES.DATA_SOURCE]: ITEM_TYPES.LIST
  };

  if (isFeatureEnabled(FEATURE_NAMES.FormResource)) {
    itemTypesMap[RESOURCE_TYPES.FORM] = ITEM_TYPES.FORM;
  }

  const newPortalItems = selectedResourcesArray.map(({
    name,
    title,
    id,
    type: reportType = 'reports', // Will be only used for reports,
    isTeamAsset = false,
    ...restResource
  }) => {
    const resourceSubType = (resourceType === RESOURCE_TYPES.REPORT) ? { resourceSubType: reportType } : {};
    const resourceURL = resourceType === RESOURCE_TYPES.FORM && isFeatureEnabled(FEATURE_NAMES.FormResource) ? restResource.url : generateResourceURL(resourceType, id, appID, reportType);
    return {
      ...getItemDefaults(itemTypesMap[resourceType]),
      resourceType,
      ...resourceSubType,
      resourceID: id,
      resourceURL,
      title: title || name,
      isTeamAsset,
      ...(page && page !== 'homepage') && { page }
    };
  });

  yield put(ACTION_CREATORS.addPortalItemAction(newPortalItems, newPortalOrder, withClicking));
}

export function* watchLeftPanelItemClick({ payload: type }) {
  const getItemDefaults = yield select(SELECTORS.getItemDefaultsGetter);
  const lastSelectedItemOrder = yield select(SELECTORS.getLastSelectedItemOrder);
  const isMultiPage = yield select(SELECTORS.getIsMultiPage);
  const pageWithMaxPageOrder = yield select(SELECTORS.getPageWithMaxPageOrder);
  const lastInteractedPageID = yield select(SELECTORS.getLastInteractedPageID);
  const firstPageID = yield select(SELECTORS.getFirstPageID);
  const selectedPortalItems = yield select(SELECTORS.getSelectedPortalItems);
  const [selectedPortalItem] = selectedPortalItems ?? [];
  const lastPageID = isMultiPage && pageWithMaxPageOrder.id;
  const page = (lastInteractedPageID || (!selectedPortalItem && lastPageID)) || firstPageID;
  const pageProps = yield select(SELECTORS.getPageByID(page));

  switch (!!pageProps?.linkedItemID || type) {
    case ITEM_TYPES.FORM:
      if (isFeatureEnabled(FEATURE_NAMES.FormResource)) {
        yield put(ACTION_CREATORS.showGenericModalAction({
          name: MODALS.RESOURCE_PICKER_MODAL,
          resourceType: RESOURCE_TYPES.FORM,
          page,
          withClicking: true
        }));
      } else {
        yield put(ACTION_CREATORS.showGenericModalAction({
          name: MODALS.FORM_PICKER_MODAL,
          page,
          withClicking: true
        }));
      }
      return;
    case ITEM_TYPES.TABLE_LINK:
      yield put(ACTION_CREATORS.showGenericModalAction({
        name: MODALS.RESOURCE_PICKER_MODAL,
        resourceType: RESOURCE_TYPES.TABLE,
        page,
        withClicking: true
      }));
      return;
    case ITEM_TYPES.REPORT_LINK:
      yield put(ACTION_CREATORS.showGenericModalAction({
        name: MODALS.RESOURCE_PICKER_MODAL,
        resourceType: RESOURCE_TYPES.REPORT,
        page,
        withClicking: true
      }));
      return;
    case ITEM_TYPES.SENTBOX_LINK:
      yield put(ACTION_CREATORS.showGenericModalAction({
        name: MODALS.RESOURCE_PICKER_MODAL,
        resourceType: RESOURCE_TYPES.SENTBOX,
        page,
        withClicking: true
      }));
      return;
    case ITEM_TYPES.SIGN_LINK:
      yield put(ACTION_CREATORS.showGenericModalAction({
        name: MODALS.RESOURCE_PICKER_MODAL,
        resourceType: RESOURCE_TYPES.SIGN,
        page,
        withClicking: true
      }));
      return;
    default: // For other types
      const itemProps = getItemDefaults(type);
      yield put(ACTION_CREATORS.addPortalItemAction({ ...itemProps, ...page ? { page } : {} }, lastSelectedItemOrder, true));
  }
}

function* watchItemClick({ payload }) {
  const {
    itemID, metaKey, ctrlKey, shiftKey, rightPanelMode = RightPanelModes.APP_ITEM
  } = payload;

  const isMobileMultipleSelectMode = yield select(SELECTORS.getMobileMultipleSelectionMode);
  const selectedPortalItems = yield select(SELECTORS.getSelectedPortalItems);
  const [selectedPortalItem] = selectedPortalItems;
  // const { metaKey, ctrlKey, shiftKey } = event || {};
  const multipleSelectionMode = metaKey || ctrlKey || shiftKey;

  if ((multipleSelectionMode || isMobileMultipleSelectMode)) {
    yield put(ACTION_CREATORS.selectMultipleItemAction({ selection: itemID, withRange: shiftKey }));
    return;
  }

  if (!isEqual(selectedPortalItem, itemID)) {
    // TODO:: we should handle tempIDs
    // When a new item added, initially we are waiting for a response from API to get the item's ID
    // if the user wants to update this item before its ID return from API,
    // we are selecting an empty(undefined) item in UI since this false equality check returns true.
    // leads to problems both in UI and backend
    yield put(ACTION_CREATORS.selectPortalItemAction(itemID));
    yield put(ACTION_CREATORS.setRightPanelModeAction(rightPanelMode));
  }
}

function* watchOpenRightPanelWithMode({ payload: mode }) {
  yield put(ACTION_CREATORS.setRightPanelModeAction(mode));
  const isRightPanelOpen = yield select(SELECTORS.isRightPanelOpenSelector);
  const selectedItems = yield select(SELECTORS.getSelectedPortalItems);
  if (!isEmpty(selectedItems) && mode === RightPanelModes.APP_HEADER) {
    yield put(ACTION_CREATORS.selectPortalItemAction());
  }
  if (!isRightPanelOpen) {
    yield put(ACTION_CREATORS.toggleRightPanelAction(true));
  }
}

function* watchFetchUserTeams() {
  const userTeams = yield call(API.getUserTeams);

  yield put({ type: ACTION_TYPES.FETCH_USER_TEAMS.SUCCESS, payload: userTeams });
}

function* watchFetchUserTeamPermissions() {
  const teamPermissions = yield call(API.fetchUserPermissions);
  yield put({ type: ACTION_TYPES.FETCH_USER_TEAM_PERMISSIONS.SUCCESS, payload: teamPermissions });
}

function* watchUpdateAppLogo({ payload }) {
  const installableProps = yield call(handleInstallableAppIcon, payload);

  yield put(ACTION_CREATORS.updatePortalPropAction(installableProps));
  yield put(ACTION_CREATORS.trackEventAction({ action: 'appUpdated', target: { props: Object.keys(installableProps) } }));
}

function* watchOnStageClick() {
  const isAppCoverCropperActive = yield select(SELECTORS.getAppCoverCropState);
  const isAppHeaderCropperActive = yield select(SELECTORS.getAppHeaderCropState);
  const isRightPanelOpen = yield select(SELECTORS.isRightPanelOpenSelector);
  const isLeftPanelOpen = yield select(SELECTORS.isLeftPanelOpenSelector);
  const selectedItems = yield select(SELECTORS.getSelectedPortalItems);
  const selectedPage = yield select(SELECTORS.getSelectedPage);
  const lastAddedItemAction = yield select(SELECTORS.getLastAddedItemIDSelector);
  const isMobile = checkMobilePhone();

  const isAnyCropperActive = isAppCoverCropperActive || isAppHeaderCropperActive;

  if (isRightPanelOpen) {
    yield put(ACTION_CREATORS.toggleRightPanelAction(false));
    yield put(ACTION_CREATORS.setRightPanelModeAction(''));
  }
  if (!isAnyCropperActive) {
    if (!isEmpty(selectedItems)) {
      yield put(ACTION_CREATORS.selectPortalItemAction(''));
    }
    if (selectedPage) {
      yield put(ACTION_CREATORS.selectPageAction());
    }
    if (lastAddedItemAction) {
      yield put(ACTION_CREATORS.clearLastAddedItemAction());
    }
  }
  if (isMobile && isLeftPanelOpen) {
    yield put(ACTION_CREATORS.toggleLeftPanelAction(false));
  }
}

function* watchShowDonationItem() {
  const allItems = yield select(SELECTORS.getPortalItems);
  const activePageID = yield select(SELECTORS.getActivePageID);
  const donationItem = allItems.find(item => item.type === ITEM_TYPES.DONATION);
  const donationItemPage = donationItem?.page;

  yield put(ACTION_CREATORS.trackEventAction({ action: 'topBarDonationButtonClicked' }));

  if (activePageID !== donationItemPage) {
    yield put(ACTION_CREATORS.navigateToAction({ to: DESTINATION_TYPES.PAGE, pageID: donationItemPage }));
    yield take(ACTION_TYPES.ACTIVE_PAGE);
  }

  const donationItemElement = document.querySelector('div[type="DONATION"]');
  donationItemElement.scrollIntoView({ block: 'center' });
}

function* watchOnDragEnd({ payload }) {
  const getItemDefaults = yield select(SELECTORS.getItemDefaultsGetter);
  const { source, destination, draggableId } = payload;

  if (!destination) return;
  const { droppableId: srcDropppableID, index: oldIndex } = source;
  const { droppableId: destDropppableID, index: newIndex } = destination;
  if (!destDropppableID.includes('droppable')) return; // Forbidden to drop outside

  const page = destDropppableID.split('_')[1];
  const oldpage = srcDropppableID.split('_')[1];

  const isSourceDetailPage = yield select(SELECTORS.getIsDataSourcePage(oldpage));

  if (isSourceDetailPage && page !== oldpage) {
    yield put(ACTION_CREATORS.toastAction({
      message: 'Elements cannot be moved from details page'
    }));
    return;
  }

  const { type: itemType } = yield select(SELECTORS.getPortalItemByIDSelector(draggableId));
  const isDestinationDetailPage = yield select(SELECTORS.getIsDataSourcePage(page));

  if (isDestinationDetailPage && !(AVAILABLE_DETAIL_PAGE_ITEMS.includes(draggableId) || AVAILABLE_DETAIL_PAGE_ITEMS.includes(itemType))) {
    yield put(ACTION_CREATORS.toastAction({
      message: 'Element is not supported for detail page'
    }));

    yield put(ACTION_CREATORS.trackEventAction({ action: 'detailPageUnsupportedItemAddition', target: { itemType: itemType ?? draggableId, pageID: page } }));
    return;
  }

  const pageObject = page && page !== 'homepage' ? { page } : {};
  if (Object.values(ITEM_TYPES).includes(draggableId) || isItemTypeWidget(draggableId)) {
    // New Addition
    const droppedItem = getItemDefaults(draggableId);
    const newItemOrder = newIndex + 1;
    const { type } = droppedItem;

    if (type === ITEM_TYPES.FORM && !isFeatureEnabled(FEATURE_NAMES.FormResource)) {
      yield put(ACTION_CREATORS.showGenericModalAction({
        name: MODALS.FORM_PICKER_MODAL,
        portalOrder: newItemOrder,
        page
      }));
      return;
    }

    if (resourceLinkTypes.includes(type)) {
      yield put(ACTION_CREATORS.showGenericModalAction({
        name: MODALS.RESOURCE_PICKER_MODAL,
        resourceType: resourceTypeMap[type],
        page,
        addOrder: newItemOrder
      }));
      return;
    }

    yield put(ACTION_CREATORS.addPortalItemAction({ ...droppedItem, ...pageObject }, newItemOrder));
    return;
  }
  yield put(ACTION_CREATORS.changeOrderAction({
    oldIndex, newIndex, page, elementID: draggableId, oldpage: oldpage || ''
  }));

  yield put(ACTION_CREATORS.updateLastInteractedPageIDAction(page));
}

function* pushNotificationFlowPublic() {
  const status = yield select(SELECTORS.getPushNotificationStatus);
  if (!isYes(status)) {
    return;
  }
  const appID = yield select(SELECTORS.getAppID);
  if (!appID) {
    return;
  }

  if (!PushManager.isSupported()) {
    if (!(!isPWA() && isIosSafari())) {
      yield put(ACTION_CREATORS.trackEventAction({ action: 'pushNotificationNotSupported' }));
    }
    return;
  }

  const portalPush = new PushManager({
    resourceType: 'portal',
    resourceId: appID
  });

  const isSubscribed = yield call([portalPush, portalPush.isSubscribed]);
  const swRegistration = yield window.navigator.serviceWorker.ready;

  if (!isSubscribed && swRegistration && !isPushPermissionRecentlyDismissed(appID)) {
    yield put(ACTION_CREATORS.setUIProp(UI_PROPS.notificationPermissionState, NOTIFICATION_PERMISSION_STATES.VISIBLE));
    yield put(ACTION_CREATORS.trackEventAction({ action: 'notificationPermissionShown' }));
    yield takeLatest(ACTION_TYPES.PUSH_NOTIFICATION_ALLOW, function* allow() {
      yield put(ACTION_CREATORS.trackEventAction({ action: 'notificationPermissionAllowClicked' }));
      yield put(ACTION_CREATORS.setUIProp(UI_PROPS.notificationPermissionState, NOTIFICATION_PERMISSION_STATES.LOADING));
      const username = yield select(SELECTORS.getUsername);
      try {
        yield call([portalPush, portalPush.subscribe], { username });
        yield put(ACTION_CREATORS.setUIProp(UI_PROPS.notificationPermissionState, NOTIFICATION_PERMISSION_STATES.HIDDEN));
      } catch (err) {
        if (err instanceof SubscriptionError) {
          switch (err.name) {
            case 'PermissionDefault':
              yield put(ACTION_CREATORS.setUIProp(UI_PROPS.notificationPermissionState, NOTIFICATION_PERMISSION_STATES.VISIBLE));
              break;
            case 'PermissionDenied':
              yield put(ACTION_CREATORS.setUIProp(UI_PROPS.notificationPermissionState, NOTIFICATION_PERMISSION_STATES.DENIED));
              yield put(ACTION_CREATORS.trackEventAction({ action: 'notificationPermissionDenied' }));
              break;
            case 'NoSwRegistration':
            case 'DeviceNotSupported':
              yield put(ACTION_CREATORS.setUIProp(UI_PROPS.notificationPermissionState, NOTIFICATION_PERMISSION_STATES.HIDDEN));
              console.error('Error on registering push subscription:', err);
              break;
            default:
              console.error('Error on registering push subscription:', err);
          }
        } else {
          console.log(err);
          yield put(ACTION_CREATORS.setUIProp(UI_PROPS.notificationPermissionState, NOTIFICATION_PERMISSION_STATES.ERROR));
        }
      }
    });
    yield takeLatest(ACTION_TYPES.PUSH_NOTIFICATION_DISMISS, function* dismiss() {
      yield put(ACTION_CREATORS.trackEventAction({ action: 'notificationPermissionDismissed' }));
      const notificationDismissKey = `${NOTIFICATION_DISMISS_KEY}_${appID}`;
      StorageHelper.setLocalStorageItem({
        key: notificationDismissKey,
        value: Date.now()
      });
      yield put(ACTION_CREATORS.setUIProp(UI_PROPS.notificationPermissionState, NOTIFICATION_PERMISSION_STATES.HIDDEN));
    });
  }
}

function* pushNotificationHistoryPolling() {
  const pendingCampaign = yield select(SELECTORS.hasPendingPushCampaign);
  const serverTimeZone = 'America/New_York';
  if (pendingCampaign && Moment.tz(pendingCampaign.createdAt, serverTimeZone).isAfter(Moment.tz(serverTimeZone).subtract(2, 'minutes'))) {
    yield delay(3000);
    yield put({ type: ACTION_TYPES.FETCH_NOTIFICATON_HISTORY.REQUEST });
  }
}

function* pushNotificationFlow() {
  const appMode = yield select(SELECTORS.getAppModeSelector);
  if (appMode === APP_MODES.public) {
    yield spawn(pushNotificationFlowPublic);
  } else if (appMode === APP_MODES.builder) {
    const currentStep = yield select(SELECTORS.getCurrentStep);
    const currentSubtab = yield select(SELECTORS.getCurrentSubTab);
    if (currentStep === 'settings' && currentSubtab === 'pushNotification') { // TODO: check is push enabled (performance)
      yield put({ type: ACTION_TYPES.FETCH_NOTIFICATON_HISTORY.REQUEST });
      yield put({ type: ACTION_TYPES.FETCH_NOTIFICATION_STATS.REQUEST });
    }
    yield takeLatest(ACTION_TYPES.SET_ACTIVE_BUILDER_PAGE, function* handler({ payload }) {
      if (payload.subTab === 'pushNotification') {
        yield put({ type: ACTION_TYPES.FETCH_NOTIFICATON_HISTORY.REQUEST });
        yield put({ type: ACTION_TYPES.FETCH_NOTIFICATION_STATS.REQUEST });
      }
    });
    yield takeLatest([ACTION_TYPES.SEND_PUSH_NOTIFICATION.SUCCESS, ACTION_TYPES.FETCH_NOTIFICATON_HISTORY.SUCCESS], pushNotificationHistoryPolling);
  }
}

function* uxrExitIntentSurveyFlow() {
  const key = 'appBuilder:uxrExitIntentSurveySeen';
  const isSurveySeen = StorageHelper.getLocalStorageItem({ key });
  const isBuilder = yield select(SELECTORS.getIsBuilder);
  const currentUser = yield select(SELECTORS.getUser);
  const isGuestUser = currentUser.account_type?.name === 'GUEST';
  const isAppFirstUser = yield select(SELECTORS.getIsAppFirstUser);
  const portalID = yield select(SELECTORS.getPortalIDSelector);

  if (!isBuilder || !isAppFirstUser || isSurveySeen) return;

  const selectors = ['.jNewHeader-logo', '.jNewHeader-appBoxContent'];
  yield delay(1000);
  const elements = selectors.map(s => document.querySelector(s));

  const { register, closeAll } = eventChannelRegistry();

  function* showSurveyModal() {
    yield put(ACTION_CREATORS.showGenericModalAction({ name: MODALS.EXIT_INTENT_SURVEY_MODAL, username: currentUser.username, appID: portalID }));
    StorageHelper.setLocalStorageItem({ key, value: true });
    closeAll();
  }

  const clickChannel = register(emitter => {
    elements.forEach(el => el?.addEventListener('click', emitter));
    return () => {
      elements.forEach(el => el?.removeEventListener('click', emitter));
    };
  });

  yield takeEvery(clickChannel, function* handler(event) {
    event.preventDefault();
    event.stopPropagation();
    yield fork(showSurveyModal);
  });

  if (isGuestUser) {
    const mouseEnterChannel = register(emitter => {
      document.addEventListener('mouseenter', emitter);
      return () => document.removeEventListener('mouseenter', emitter);
    });

    const timeoutMouseLeaveChannel = register(emitter => {
      const timeout = setTimeout(() => {
        document.addEventListener('mouseleave', emitter);
      }, 2 * 60 * 1000);
      return () => {
        clearTimeout(timeout);
        document.removeEventListener('mouseleave', emitter);
      };
    });

    yield takeEvery(timeoutMouseLeaveChannel, function* handler(event) {
      if (event.clientY < 0) { // only upper side of the window
        const { timeout } = yield race({
          mouseEnter: take(mouseEnterChannel),
          timeout: delay(1000)
        });
        if (timeout) {
          yield fork(showSurveyModal);
        }
      }
    });
  } else {
    // const unloadChannel = register(emitter => {
    //   window.addEventListener('beforeunload', emitter);
    //   return () => {
    //     window.removeEventListener('beforeunload', emitter);
    //   };
    // }, false); // TODO: replace w/ !isGuestUser

    // yield takeEvery(unloadChannel, function* handler() {
    // TODO: send email
    // });
  }
}

function* whatsNewModalFlow() {
  const isBuilder = yield select(SELECTORS.getIsBuilder);
  if (!isBuilder) return;
  const currentUser = yield select(SELECTORS.getUser);

  const features = useEnabledFeatures();
  const enabledFeatures = Object.keys(features).filter(key => features[key]);
  const viableFeatureModalKeys = Object.keys(WHATS_NEW_MODAL_FEATURES)
    .filter(viableKey => enabledFeatures.includes(viableKey) && (WHATS_NEW_MODAL_FEATURES[viableKey]?.condition?.({ currentUser }) ?? true));

  if (viableFeatureModalKeys.length) {
    yield put(ACTION_CREATORS.showWhatsNewModalAction(viableFeatureModalKeys));
    yield all(
      viableFeatureModalKeys.map(key => put(ACTION_CREATORS.trackEventAction({ action: `${key.charAt(0).toLowerCase() + key.slice(1)}` })))
    );
  }
}

function* fetchUserManagementUsers() {
  const appID = yield select(SELECTORS.getAppID);

  try {
    const { users, sheetID, sheetViewID } = yield call(API.fetchUserManagementUsers, appID);
    if (!users) {
      console.error('Expected users on fetch user management users but received: ', users);
      return;
    }
    yield put({ type: ACTION_TYPES.SET_USER_MANAGEMENT_USERS, payload: { users, sheetID, sheetViewID } });
  } catch (err) {
    console.log(err);
  }
}

function* userManagement() {
  const userManagementEnabledUser = isFeatureEnabled(FEATURE_NAMES.UserManagement);
  const isBuilder = yield select(SELECTORS.getIsBuilder);
  if (!userManagementEnabledUser || !isBuilder) return;
  yield takeLatest(ACTION_TYPES.SET_ACTIVE_BUILDER_PAGE, function* handler({ payload }) {
    if (payload.subTab === publishNavPaths.quickShare) {
      yield fetchUserManagementUsers();
    }
  });
}

function* watchAppStatus({ payload: status }) {
  if (status === 'ready') {
    yield spawn(fetchResources);
    yield spawn(fetchShareInfoFlow);
    yield spawn(watchInstallableIconBuilderFlow);
    yield spawn(fetchStorePropertiesFlow);
    yield spawn(checkAddElementPulseVisible);
    yield spawn(pushNotificationFlow);
    yield spawn(uxrExitIntentSurveyFlow);
    yield spawn(dsFetchColumnsFlow);
    yield spawn(aiAssitant);
    yield spawn(userManagement);

    yield spawn(whatsNewModalFlow);
    // A/B Test: ctAppNameIconModal
    yield spawn(initAppNameIconModalAbTest);
  }
}

function* watchOnMultipleItemUpdate({ payload: props }) {
  const selectedItemProps = yield select(SELECTORS.getSelectedPortalItemsWithInfo);
  const designatedItemID = yield select(SELECTORS.getDesignatedItemIDSelector);
  const version = yield select(SELECTORS.getAppVersionSelector);

  const getItemWithDefaults = type => useItemDefaults(type, version);
  const selectedItemsWithDefaults = selectedItemProps.map(({ type, ...rest }) => ({ ...getItemWithDefaults(type), ...rest }));

  const { schemeID } = props;

  if (designatedItemID) {
    const designatedProperties = ['itemBgColor', 'itemFontColor', 'itemBorderColor', 'itemTextAlignment'];
    const propValues = Object.keys(props);
    const shouldUpdateDesignated = some(propValues, prop => designatedProperties.includes(prop));
    if (shouldUpdateDesignated) {
      yield put(ACTION_CREATORS.updatePortalAction({ designatedItemProps: '' }));
    }
  }

  const designatedProperties = schemeID && getColoredPropertiesBySchemeID(selectedItemsWithDefaults, schemeID, version);
  const properties = schemeID ? designatedProperties.itemsToChange : getFilteredProperties(selectedItemsWithDefaults, props, version);

  yield put(ACTION_CREATORS.updateMultipleItemAction(properties));
  yield put(ACTION_CREATORS.trackEventAction({ action: 'multipleItemsUpdated', target: { props: Object.keys(props) } }));
}

function* watchOnDeletePage({ payload }) {
  const { pageID, type = '' } = payload;
  const appItems = yield select(SELECTORS.getPortalItems);
  const pageHasItem = appItems.find(({ page }) => page && page.toString() === pageID.toString());
  const pageProps = yield select(SELECTORS.getPageByID(pageID));
  if (pageHasItem) {
    yield put(ACTION_CREATORS.showGenericModalAction({
      name: MODALS.DELETE_PAGE_MODAL, pageID, type, pageProps
    }));
  } else {
    yield put(ACTION_CREATORS.deletePageAction(pageID, false, type));
  }
}

function* watchReplaceFormItem({ payload: { itemID, formID } }) {
  const appID = yield select(SELECTORS.getAppID);
  const sentboxItem = yield select(SELECTORS.getSentboxByFormID(itemID));

  const form = yield call(API.replaceFormItem, { appID, itemID, formID });

  yield put({ type: ACTION_TYPES.REPLACE_FORM_ITEM.SUCCESS, payload: { form, itemID } });

  if (sentboxItem) {
    const {
      id, type, name, title
    } = yield select(SELECTORS.getFormByID(formID));
    yield put(ACTION_CREATORS.updateItemPropAction({
      itemID: sentboxItem.id,
      prop: {
        resourceID: id,
        resourceTitle: '',
        title: name || title,
        resourceURL: generateResourceURL(ITEM_TYPES.SENTBOX_LINK, id, appID, type)
      }
    }));
  }
}

function* watchDonations({ payload: { title, description, selectedPrice: price } }) {
  const appID = yield select(SELECTORS.getAppID);
  const appIcon = yield select(SELECTORS.getInstallableIconURL);
  const donationCartData = {
    donation: [{
      description,
      images: [appIcon],
      name: title,
      options: [],
      pid: '1000',
      price,
      quantity: '1'
    }]
  };

  yield put(ACTION_CREATORS.setCartProductsAction(donationCartData));

  const { checkoutKey } = yield call(API.updateCart, appID, JSON.stringify(donationCartData));

  if (checkoutKey) {
    yield put(ACTION_CREATORS.setCheckoutKeyAction(checkoutKey));
  }
}

function* watchShowWhatsNewModal({ payload: whatsNewKeys }) {
  const storageKeys = whatsNewKeys
    .reduce((acc, key) => ({ ...acc, [key]: `whats_new_portal_${key.split(/(?=[A-Z])/).map(word => word.toLowerCase()).join('_')}` }), {});

  const unseenedKeys = Object.entries(storageKeys).filter(([, localStorageKey]) => global?.localStorage?.getItem(localStorageKey) !== '1');

  if (unseenedKeys.length && !isTestingEnv()) {
    const unseenedWhatsNewKeys = unseenedKeys.map(key => key[0]);
    yield put(ACTION_CREATORS.showGenericModalAction({ name: MODALS.WHAT_IS_NEW, whatsNewKeys: unseenedWhatsNewKeys }));
    unseenedKeys.forEach(([, localStorageKey]) => global?.localStorage?.setItem(localStorageKey, 1));
  }
}

function* watchFetchStorePropertiesSuccess() {
  const cartProducts = yield select(SELECTORS.getCart);
  if (isEmpty(cartProducts)) {
    return;
  }
  const appID = yield select(SELECTORS.getAppID);
  const productListItems = yield select(SELECTORS.getProductListItems);
  const validCartProducts = Object.fromEntries(Object.entries(cartProducts).map(([formID, products]) => [
    formID,
    products.filter(({ pid }) => productListItems.find(item => item.formID === formID)?.products?.some(product => product.pid === pid))
  ]));

  if (!isEqual(validCartProducts, cartProducts)) {
    yield put(ACTION_CREATORS.setCartProductsAction(validCartProducts));
    yield call(API.updateCart, appID, JSON.stringify(validCartProducts));
  }

  const validPriceCartProducts = Object.fromEntries(Object.entries(cartProducts).map(([formID, products]) => [
    formID,
    products.filter(({ pid, price }) => {
      const productListItem = productListItems.find(item => item.formID === formID)?.products?.find(product => product.pid === pid);
      return productListItem && productListItem.price === price;
    })
  ]));

  if (!isEqual(validPriceCartProducts, validCartProducts)) {
    yield put(ACTION_CREATORS.setIsChangedPriceInCartAction(true));
    yield put(ACTION_CREATORS.setCartProductsAction(validPriceCartProducts));
    yield call(API.updateCart, appID, JSON.stringify(validPriceCartProducts));
  }
}

function* watchFetchStorePropertiesRequest() {
  const portalID = yield select(SELECTORS.getPortalIDSelector);
  const { cartProducts, favoriteProducts, checkoutKey } = yield call(API.getStorePropertiesOfUser, portalID);

  yield put({ type: ACTION_TYPES.FETCH_STORE_PROPERTIES.SUCCESS, payload: { cartProducts, favoriteProducts, checkoutKey } });
}

function* watchFetchNotificationHistory() {
  const appID = yield select(SELECTORS.getAppID);
  try {
    const historyList = yield call(API.getNotificationHistory, appID);
    yield put({ type: ACTION_TYPES.FETCH_NOTIFICATON_HISTORY.SUCCESS, notificationHistory: historyList });
  } catch (err) {
    console.error(err);
  }
}

function* watchFetchNotificationStats() {
  const appID = yield select(SELECTORS.getAppID);
  try {
    const stats = yield call(API.getNotificationStats, appID);
    yield put({ type: ACTION_TYPES.FETCH_NOTIFICATION_STATS.SUCCESS, stats });
  } catch (err) {
    console.error(err);
  }
}

function* watchSendPushNotificationError({ payload: error }) {
  if (error?.data?.responseCode === 401) {
    let message;
    switch (error.data.message) {
      case NOTIFICATION_ERROR_MESSAGES.HOURLY_LIMIT:
        message = t('You have reached the maximum number of push notifications allowed per hour. Please try again later.');
        break;
      case NOTIFICATION_ERROR_MESSAGES.DAILY_LIMIT:
        message = t('You have reached the maximum number of push notifications allowed for today. Please try again tomorrow.');
        break;
      case NOTIFICATION_ERROR_MESSAGES.NOT_ALLOWED:
        message = t("You're not authorized to use this feature.");
        break;
      default:
        message = t('Something went wrong. Please try again.');
    }
    yield put(ACTION_CREATORS.toastAction({
      message,
      error: true,
      type: 'error'
    }));
  }
}

function* watchSendPushNotification({ payload: { title, message, reset } }) {
  const appID = yield select(SELECTORS.getAppID);
  const notificationHistoryList = yield select(SELECTORS.notificationHistory);

  try {
    const response = yield call(API.sendPushNotification, { appID, title, message });
    const newHistoryList = [response, ...notificationHistoryList];

    yield put({ type: ACTION_TYPES.SEND_PUSH_NOTIFICATION.SUCCESS, payload: newHistoryList });
    reset();
    yield put(ACTION_CREATORS.toastAction({
      message: t('All users will receive a notification.'),
      type: 'success',
      backdrop: false
    }));
  } catch (error) {
    yield put({ type: ACTION_TYPES.SEND_PUSH_NOTIFICATION.ERROR, payload: error });
  }
}

// eslint-disable-next-line max-statements
export function* rootSagaFlow() {
  yield takeLatest(ACTION_TYPES.UPDATE_PORTAL.UNDOABLE, safeWorker(watchPortalUpdates));
  yield takeEvery(ACTION_TYPES.ADD_PORTAL_ITEMS.REQUEST, safeWorker(watchPortalItemAdditions));
  yield takeLatest(ACTION_TYPES.REMOVE_PORTAL_ITEMS.REQUEST, safeWorker(watchPortalItemRemovals));
  yield takeEvery(ACTION_TYPES.UPDATE_ORDER.UNDOABLE, safeWorker(watchItemOrderUpdate));
  yield takeLatest(ACTION_TYPES.UPDATE_ITEM_PROP.UNDOABLE, safeWorker(watchItemPropUpdate));
  yield takeLatest(ACTION_TYPES.CREATE_NEW_PORTAL.REQUEST, safeWorker(watchCreatePortal));
  yield takeLatest(ACTION_TYPES.BRANDING_BANNER_CLICK, watchBrandingButtonClick);
  yield takeLatest(ACTION_TYPES.BUILD_WITH_FORM, watchBuildOrCreate);
  yield takeEvery(ACTION_TYPES.FETCH_PORTAL.REQUEST, safeWorker(fetchPortalFlow));
  yield takeEvery(ACTION_TYPES.FETCH_SIGNS.REQUEST, safeWorker(wathcFetchSigns));
  yield takeEvery(ACTION_TYPES.FETCH_FORMS.REQUEST, safeWorker(watchFetchForms));
  yield takeEvery(ACTION_TYPES.CREATE_FORM_FROM_TEMPLATE.REQUEST, safeWorker(watchCreateFormFromTemplate));
  yield takeEvery(ACTION_TYPES.CREATE_FORM_FROM_SCRATCH.REQUEST, safeWorker(watchCreateFormFromScratch));
  yield takeEvery(ACTION_TYPES.CREATE_SIGN_DOCUMENT_FROM_TEMPLATE.REQUEST, safeWorker(watchCreateSignDocumentFromTemplate));
  yield takeEvery(ACTION_TYPES.SHOULD_WINDOW_SQUEEZE, watchWindowSqueeze);
  yield takeEvery(ACTION_TYPES.SEND_FEEDBACK.REQUEST, safeWorker(sendFeedback));
  yield takeEvery(ACTION_TYPES.FETCH_APPS.REQUEST, safeWorker(watchFetchUserApps));
  yield takeEvery(ACTION_TYPES.SELECT_ALL_ITEMS, watchSelectAllItems);
  yield takeEvery(ACTION_TYPES.REMOVE_SELECTED_ITEMS, watchDeleteSelectedItems);
  yield takeLatest(ACTION_TYPES.UPDATE_MULTIPLE_ITEM.UNDOABLE, safeWorker(watchMultipleItemUpdate));
  yield takeEvery(action => /^@UI/ig.test(action.type), watchUIupdates);
  yield takeEvery(isAPIErrorAction, watchErrors);
  yield takeEvery(ACTION_TYPES.SELECT_MULTIPLE_PORTAL_ITEM, watchMultipleSelection);
  yield takeEvery(
    [
      ACTION_TYPES.UPDATE_ITEM_PROP.REQUEST,
      ACTION_TYPES.CALCULATE_DONE_COUNT,
      ACTION_TYPES.UPDATE_ITEM_PROP.WITHOUT_DEBOUNCE,
      ACTION_TYPES.UPDATE_ITEM_PROP.WITH_DEBOUNCE
    ],
    watchDoneItemProgress);
  yield takeEvery(ACTION_TYPES.CALCULATE_TODO_COUNT, watchTodoItemProgress);
  yield takeEvery(ACTION_TYPES.RESTART_PROGRESS, watchRestartProgress);
  yield takeEvery(ACTION_TYPES.UPDATE_ITEM_PROP.WITHOUT_DEBOUNCE, watchItemPropUpdateRequest);
  yield debounce(500, ACTION_TYPES.UPDATE_ITEM_PROP.WITH_DEBOUNCE, watchItemPropUpdateRequest);
  yield takeEvery([ACTION_TYPES.UPDATE_ITEM_PROP.SUCCESS, ACTION_TYPES.UPDATE_MULTIPLE_ITEM.SUCCESS], watchProgressBarAvailability);
  yield takeEvery(ACTION_TYPES.ITEM_ADDITION_PORTAL_ORDER_WORKER.REQUEST, safeWorker(watchPortalOrderInItemAddition));
  yield takeEvery(ACTION_TYPES.WATCH_HEADING_ITEM_FOR_PAGE_NAMING, watchHeadingItemToPageNaming);
  yield takeEvery(ACTION_TYPES.UPDATE_PORTAL_SLUG.REQUEST, updateSlug);
  yield takeEvery(ACTION_TYPES.LAYOUT_CHANGE.REQUEST, safeWorker(watchLayoutChange));
  yield takeEvery(ACTION_TYPES.FETCH_USER_TEAMS.REQUEST, safeWorker(watchFetchUserTeams));
  yield takeEvery(ACTION_TYPES.FETCH_USER_TEAM_PERMISSIONS.REQUEST, safeWorker(watchFetchUserTeamPermissions));

  yield takeEvery([ACTION_TYPES.ADD_NEW_PAGE.UNDOABLE, ACTION_TYPES.UPDATE_PAGE.UNDOABLE, ACTION_TYPES.DELETE_PAGE.UNDOABLE], safeWorker(pageActions));
  yield takeEvery(ACTION_TYPES.CHANGE_PAGE_ORDER.REQUEST, safeWorker(watchPageUpdate));
  yield takeEvery(ACTION_TYPES.UPDATE_ORDER_WORKER.REQUEST, safeWorker(watchItemSorting));
  yield takeEvery(ACTION_TYPES.UPDATE_USER.REQUEST, safeWorker(watchUserSettingUpdate));
  yield takeEvery(ACTION_TYPES.CONTINUE_AS_USER.REQUEST, safeWorker(watchContinueAsUser));
  yield takeEvery(ACTION_TYPES.DUPLICATE_ITEM, safeWorker(watchItemDuplication));
  yield takeEvery(ACTION_TYPES.REPLACE_FORM_ITEM.REQUEST, safeWorker(watchReplaceFormItem));

  yield takeEvery(ACTION_TYPES.USER_CHANGE, safeWorker(watchUserChange));

  yield takeEvery(ACTION_TYPES.UPDATE_PORTAL_USER_PROPS.REQUEST, safeWorker(watchUpdatePortalUserProps));

  yield takeEvery(ACTION_TYPES.SET_CHECKOUT_FORM_STATUS, watchCheckoutFormStatusChanges);

  yield takeEvery(ACTION_TYPES.NAVIGATION_ITEM_CLICK, watchNavigationItemClick);

  yield takeEvery(ACTION_TYPES.ON_TODO_COMPLETE, watchSetTodoComplete);

  yield takeEvery(ACTION_TYPES.CREATE_PORTAL_WITH_STORE, createNewPortalWithStore);

  yield takeEvery(ACTION_TYPES.CREATE_PORTAL_WITH_TEMPLATE, createNewPortalWithTemplate);

  yield takeEvery(ACTION_TYPES.CREATE_PORTAL_WITH_DONATION, createNewPortalWithDonation);

  yield takeEvery(ACTION_TYPES.SET_IS_APP_DONE, watchIsAppDone);

  yield takeEvery(ACTION_TYPES.ON_FORM_PICKER_MODAL_CONFIRM, watchFormPickerModalConfirm);

  yield takeEvery(ACTION_TYPES.ON_RESOURCE_PICKER_MODAL_CONFIRM, watchResourcePickerModalConfirm);

  yield takeEvery(ACTION_TYPES.ON_LEFT_PANEL_ITEM_CLICK, watchLeftPanelItemClick);

  yield takeEvery(ACTION_TYPES.ON_ITEM_CLICK, watchItemClick);

  yield takeEvery(ACTION_TYPES.ON_STAGE_CLICK, watchOnStageClick);
  yield takeEvery(ACTION_TYPES.SHOW_DONATION_ITEM, watchShowDonationItem);
  yield takeEvery(ACTION_TYPES.ON_DRAG_END, watchOnDragEnd);
  yield takeEvery(ACTION_TYPES.ON_DELETE_PAGE, watchOnDeletePage);
  yield takeEvery(ACTION_TYPES.ON_MULTIPLE_ITEM_UPDATE, watchOnMultipleItemUpdate);

  yield takeEvery(ACTION_TYPES.OPEN_RIGHT_PANEL_WITH_MODE, watchOpenRightPanelWithMode);

  yield takeEvery(ACTION_TYPES.UPDATE_APP_LOGO.REQUEST, watchUpdateAppLogo);

  yield takeEvery(ACTION_TYPES.SET_APP_STATUS, watchAppStatus);

  // yield takeEvery(ACTION_TYPES.SET_APP_STATUS, watchUXRSurveyModal);

  yield takeEvery(ACTION_TYPES.FETCH_PORTAL.SUCCESS, watchFetchPortalSuccess);

  yield takeEvery(ACTION_TYPES.DONATE, watchDonations);

  yield takeEvery(ACTION_TYPES.SHOW_WHATS_NEW_MODAL, watchShowWhatsNewModal);
  yield takeEvery(ACTION_TYPES.FETCH_STORE_PROPERTIES.REQUEST, watchFetchStorePropertiesRequest);
  yield takeEvery(ACTION_TYPES.FETCH_STORE_PROPERTIES.SUCCESS, watchFetchStorePropertiesSuccess);
  yield takeLatest(ACTION_TYPES.INIT_ELEMENTS_PANEL_AB_TEST, initAppElementPanelAbTest);
  yield takeEvery(ACTION_TYPES.SEND_PUSH_NOTIFICATION.REQUEST, watchSendPushNotification);
  yield takeEvery(ACTION_TYPES.SEND_PUSH_NOTIFICATION.ERROR, watchSendPushNotificationError);
  yield takeEvery(ACTION_TYPES.FETCH_NOTIFICATON_HISTORY.REQUEST, watchFetchNotificationHistory);
  yield takeEvery(ACTION_TYPES.FETCH_NOTIFICATION_STATS.REQUEST, watchFetchNotificationStats);

  yield spawn(productListActions);
  yield spawn(searchInProductsActions);
  yield spawn(navigationActions);

  yield spawn(fetchUserSlugFlow);
  yield spawn(fetchWidgetsFlow);
  yield spawn(templateCategoriesFlow);
  yield spawn(svgIconsFlow);
  yield spawn(appStatusChecker);
  yield spawn(fetchUserFlow);
  yield spawn(fetchPortalFlow);
  yield spawn(fetchEnvironmentFlow); // TODO why? Ask Berkay
  yield spawn(trackEvents);
  yield spawn(shareFlow);
  yield spawn(watchAPIRequests);
  yield spawn(watchUIPanelsChanges);
  yield spawn(keepClosedUIPanels);
  yield spawn(watchModals);
  yield spawn(watchUndoableActions);
  yield spawn(watchUndoRedoActions);
  yield spawn(watchToastActions);
  yield spawn(watchAppToast);
  yield spawn(watchNetworkStatus);
  yield spawn(watchStylingActions);
  yield spawn(fetchCDNConfigFlow);
  yield spawn(fetchUserApps);
  yield spawn(eventsFlow);
  if (getTeamID()) {
    yield spawn(fetchTeamFlow);
    yield spawn(collaborationFlow);
  }
  yield spawn(activateFullStoryOnTheFly);
  yield spawn(productList); // it is new
  yield spawn(checkoutFormFlow);
  yield spawn(uxrExitIntentSurveyFlow);
  yield spawn(dataSourceFlow);
}
