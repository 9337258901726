/* eslint-disable @jotforminc/no-native-button */
import React, {
  useRef, useState, useEffect, Fragment
} from 'react';
import {
  arrayOf, object, func, string, number, bool
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';

import { IconChevronLeft, IconChevronRight } from '@jotforminc/svg-icons';
import { ScTemplates } from '../ScTemplates';
import TemplateItemContainer from '../TemplateItem/TemplateItemContainer';
import { getDeviceType } from '../../getDeviceType';
import { templateConstants } from '../../templateConstants';

const dummyTemplatesArray = [
  { screenshot: 'skeleton', title: 'skeleton', id: 'skeleton-1' },
  { screenshot: 'skeleton', title: 'skeleton', id: 'skeleton-2' },
  { screenshot: 'skeleton', title: 'skeleton', id: 'skeleton-3' },
  { screenshot: 'skeleton', title: 'skeleton', id: 'skeleton-4' },
  { screenshot: 'skeleton', title: 'skeleton', id: 'skeleton-5' },
  { screenshot: 'skeleton', title: 'skeleton', id: 'skeleton-6' }
];

const SimilarTemplatesItem = ({
  similarTemplates, modalBodyRef, categoryData, onTemplateClone, templateType, source, theme, useSlider, newStandaloneActive,
  setModalContentLoading, customOnSelectTemplate, skeletonCount, selectTemplateCallback, parentTemplateID, isAgentTemplates, displayMyformsModal
}) => {
  const { newPreviewModalActive = false } = templateConstants[templateType];
  const sliderRef = useRef(null);
  const [sliderRealIndex, setSliderRealIndex] = useState(0);
  const [disableNavigationNext, setDisableNavigationNext] = useState(false);
  const [similarsLoaded, setSimilarsLoaded] = useState(false);

  const deviceType = getDeviceType();
  const isDesktop = deviceType === 'desktop';

  useEffect(() => {
    if (isAgentTemplates) {
      setSimilarsLoaded(true);
      return;
    }
    if (Object.keys(categoryData).length > 0) {
      setSimilarsLoaded(true);
    }
  }, [categoryData]);

  const handleSelectTemplateWrapper = newTemplate => {
    selectTemplateCallback();
    customOnSelectTemplate();
    setSimilarsLoaded(false);
    modalBodyRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    if (!newStandaloneActive) {
      const navigatedTemplateData = {
        ...newTemplate,
        ...(isAgentTemplates ? {
          previewURL: `https://www.jotform.com/agent-directory/preview/agent/${newTemplate.id}?isTemplatePreview=1`
        } : {})
      };
      displayMyformsModal({ template: { navigatedTemplateData }, ...navigatedTemplateData });
      return;
    }
    global?.setSelectedTemplate(newTemplate);
    setModalContentLoading(true);
  };

  const seeAlsoTemplates = (similarsLoaded && similarTemplates.length > 0) ? similarTemplates : dummyTemplatesArray.slice(0, newPreviewModalActive ? 6 : skeletonCount);

  if (seeAlsoTemplates.length <= 0) return null;

  const prettySeeAlsoTemplates = seeAlsoTemplates.length > 3 ? seeAlsoTemplates.slice(0, newPreviewModalActive ? 6 : 3) : seeAlsoTemplates;
  return (
    <div
      className={`more-templates-area${isEnterprise() ? ' no-description' : ''}`}
    >
      {
        useSlider ? (
          <>
            <div className="flex items-center gap-10 mb-2.5">
              <strong className="color-navy-700 text-xl line-height-2xl">{t(isAgentTemplates ? 'Other agents' : 'Related templates')}</strong>
              <div className="relatedTemplates-arrows splide__arrows flex gap-2">
                <button
                  type="button" className="w-8 h-8 flex items-center justify-center radius-full bg-navy-75 transform rtl:rotate-180 relatedTemplatesPrev"
                  onClick={() => {
                    if (!sliderRef.current) return;
                    sliderRef.current.swiper.slidePrev();
                    setSliderRealIndex(sliderRef.current.swiper.realIndex);
                    setDisableNavigationNext(sliderRef.current.swiper.isEnd);
                  }}
                  disabled={sliderRealIndex === 0}
                  style={{ opacity: sliderRealIndex === 0 ? 0.4 : 1, pointerEvents: sliderRealIndex === 0 ? 'none' : 'auto' }}
                >
                  <IconChevronLeft width={16} height={16} color="#343C6A" />
                </button>
                <button
                  type="button"
                  className="w-8 h-8 flex items-center justify-center radius-full bg-navy-75 transform rtl:rotate-180 relatedTemplatesNext"
                  onClick={() => {
                    if (!sliderRef.current) return;
                    sliderRef.current.swiper.slideNext();
                    setDisableNavigationNext(sliderRef.current.swiper.isEnd);
                    setSliderRealIndex(sliderRef.current.swiper.realIndex);
                  }}
                  disabled={disableNavigationNext}
                  style={{
                    opacity: disableNavigationNext ? 0.4 : 1,
                    pointerEvents: disableNavigationNext ? 'none' : 'auto'
                  }}
                >
                  <span className="sr-only">Next</span>
                  <IconChevronRight width={16} height={16} color="#343C6A" />
                </button>
              </div>
            </div>
            <ScTemplates isDesktop={isDesktop} slider>
              <Swiper
                dir='ltr'
                ref={sliderRef}
                spaceBetween={0}
                slidesPerView={4}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 40
                  }
                }}
              >
                {prettySeeAlsoTemplates.map(similiarTemplate => {
                  return (
                    <SwiperSlide key={(similiarTemplate._id || similiarTemplate.id).toString()}>
                      <TemplateItemContainer
                        source={source}
                        type={templateType}
                        useSlider={useSlider}
                        templateTheme={theme}
                        template={{ ...similiarTemplate, parentTemplateID }}
                        handleTemplateClone={onTemplateClone}
                        setSelectedTemplate={newTemplate => handleSelectTemplateWrapper(newTemplate)}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </ScTemplates>
          </>
        ) : (
          <>
            <h2>{t('More templates like this')}</h2>
            <div className='template-items-area'>
              <ScTemplates isDesktop={isDesktop}>
                <div className='boxes'>
                  {prettySeeAlsoTemplates.map(similiarTemplate => {
                    return (
                      <Fragment key={(similiarTemplate._id || similiarTemplate.id).toString()}>
                        <TemplateItemContainer
                          source={source}
                          type={templateType}
                          templateTheme={theme}
                          template={{ ...similiarTemplate, parentTemplateID }}
                          handleTemplateClone={onTemplateClone}
                          setSelectedTemplate={newTemplate => handleSelectTemplateWrapper(newTemplate)}
                        />
                      </Fragment>
                    );
                  })}
                </div>
              </ScTemplates>
            </div>
          </>
        )
      }
    </div>
  );
};

SimilarTemplatesItem.defaultProps = {
  theme: '',
  source: '',
  useSlider: false,
  skeletonCount: 3,
  categoryData: null,
  similarTemplates: [],
  parentTemplateID: '',
  modalBodyRef: undefined,
  onTemplateClone: () => {},
  customOnSelectTemplate: () => {},
  selectTemplateCallback: () => {},
  isAgentTemplates: false,
  displayMyformsModal: f => f,
  newStandaloneActive: true
};

SimilarTemplatesItem.propTypes = {
  theme: string,
  source: string,
  useSlider: bool,
  modalBodyRef: object,
  categoryData: object,
  skeletonCount: number,
  onTemplateClone: func,
  parentTemplateID: string,
  selectTemplateCallback: func,
  customOnSelectTemplate: func,
  templateType: string.isRequired,
  similarTemplates: arrayOf(object),
  setModalContentLoading: func.isRequired,
  isAgentTemplates: bool,
  displayMyformsModal: func,
  newStandaloneActive: bool
};

export default SimilarTemplatesItem;
